import React from 'react';
import {connect} from "react-redux";
import './channels-modal.scss';
import {Modal} from "react-bootstrap";
import {getPropsFromState} from "../../../redux/mapStateToProps";
import {useTranslation} from "react-i18next";
import {CloseIcon} from "../../../image";
import {
    GetChannelById,
    GetChannels
} from "../../../redux/actions";
import ChannelForm from "../../../modules/Channel/ChannelForm/ChannelForm";

function ChannelsModal(props) {
    const {onHide, channelById, createChannel} = props;
    const {t} = useTranslation();

    function onClose() {
        onHide();
        if(channelById) props.GetChannelById(channelById?.id);
        props.GetChannels();
    }

    return <Modal
        show={true}
        onHide={onClose}
        onClick={(e) => {
            e.stopPropagation()
            e.preventDefault()
        }}
        size="lg"
        className={'channels-modal'}
        centered
    >
        <div className="channels-modal-header">
            <span>{createChannel ? t('channel_create') : t('channel_editing')}</span>
            <CloseIcon onClick={onClose}/>
        </div>
        <ChannelForm
            channelId={channelById?.id}
            onClose={onClose}
        />

    </Modal>
}


const mapStateToProps = (state) => {
    return getPropsFromState(state, ['channelById', 'searchedUsers'])
};

const mapDispatchToProps = {
    GetChannels,
    GetChannelById,
};

export default connect(mapStateToProps, mapDispatchToProps)(ChannelsModal);

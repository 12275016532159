// Import packages
import React, {useEffect, useState} from 'react';
import {connect, useDispatch, useSelector} from "react-redux";

// Import assets
import './channels.scss';

// Import Components
import Section from "./section/Section";

// Import Utils
import {getPropsFromState} from "../../../../redux/mapStateToProps";
import {
    ChangeHeaderTitle,
    GetVideos,
    GetVideosSearch,
    GetPersons,
    ClearVideoList,
    GetVideosService
} from "../../../../redux/actions";
import {GetChannels} from "../../../../redux/actions";
import {useTranslation} from "react-i18next";
import Calendar from "../../../../shared/features/calendar/Calendar";
import {hasPermission} from "../../../../utils/helperFunctions";
import {Loader} from "../../../../shared/features/Loader";
import RecentVideo from "./RecentVideo/RecentVideo";
import PersonalVideos from "./PersonalVideo/PersonalVideos";
import dayjs from "dayjs";
import i18n from "i18next";
import {getChains} from "../../../../redux/actions/chainsActions";
import {generateChannelsList} from "../../../../shared/features/Chains/helpers";
import {ApiStatus} from "../../../../utils/constants";

function Channels(props) {
    const [channelsVideos, setChannelsVideos] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const channels = useSelector(state => state.channels?.channels || []);
    const channelsList = generateChannelsList(channels);
    const isFetchingVideos = useSelector(state => state.videos.isFetchingVideos);

    const getContent = async () => {
        try {
            setIsLoading(true);
            const responseChannels = await dispatch(GetChannels());
            await dispatch(GetVideosService({
                limit: 1000,
                channel_ids: (responseChannels || []).map((item) => item?.id)
            }));
            props.getChains();
            const title = t('menu_item_review');
            props.ChangeHeaderTitle(title)
            setIsLoading(false);
        } catch (e) {
            setIsLoading(false);
            console.warn(e);
        }
    }

    useEffect(() => {
        getContent();
    }, []);

    useEffect(() => {
        clearVideo();
    }, [])

    const clearVideo = async () => {
        await props.ClearVideoList();
    }

    useEffect(() => {
        if (props?.videosList?.results?.length && props?.channels?.length) {
            let list = {}
            !!props?.channels?.length && props?.channels.forEach(item => {
                list = {
                    ...list,
                    [item.id]: props?.videosList?.results?.filter(i => i.channels.includes(item.id))
                }
            })
            setChannelsVideos(list)
        }
    }, [props.videosList, props?.channels]);

    const noChannelList = !!props?.videosList?.results?.length && props?.videosList?.results?.filter(i => !i?.channels?.length)



    let x = i18n.language === 'en' ? "Personal" : "Личное";
    let newList = props?.channels?.sort((a, b) => {
        if (a.is_private) {
            return -1;
        }
        if (b.id === x) {
            return 1;
        }
        return 0;
    });
    const recentVideos = (props?.videosList?.results || [])
        .sort((a, b) => dayjs(b?.created_at).valueOf() - dayjs(a?.created_at).valueOf());

    const personalBadgeInfo = (props?.channels || []).find((item) => ['Личное', 'Personal', 'Private'].includes(item?.name));
    const personalVideos = (channelsVideos?.[personalBadgeInfo?.id] || []);

    return (
        <div className={`main-container channels-container ${props.isFullWidth ? '' : 'full'}`}>
            <div className="channels-wrap">
                {isLoading ?
                    <div className="loader-wrap">
                        <Loader siz={50}/>
                    </div>
                    :
                    <div className="channels">
                        <div className="channels__header">
                            <h5 className="page-title">
                                {t('menu_item_review')}
                            </h5>
                        </div>
                        {recentVideos.length > 0 ?
                            <div className="recent-videos">
                                <h2 className="recent-videos--title">{t('recent_channel')}</h2>
                                <div className="recent-videos__list">
                                    {(recentVideos).slice(0, 5).map((item, index) => (
                                        <RecentVideo
                                            key={index}
                                            channelsList={channelsList}
                                            video={item}
                                            isPreview={true}
                                            routs={[
                                                {
                                                    path: '/dashboard/videos/review',
                                                    name: t('menu_item_knowledge')
                                                },
                                                {
                                                    path: '/dashboard/videos/review',
                                                    name: t('menu_item_review')
                                                },
                                                {
                                                    path: '/dashboard/videos/review',
                                                    name: t('recent_channel')
                                                }
                                            ]}
                                        />
                                    ))}
                                </div>
                            </div>
                            : null
                        }
                        <PersonalVideos
                            personalBadgeInfo={personalBadgeInfo}
                            videos={personalVideos}
                        />

                        {!!newList?.length && newList?.filter((item) => item?.id !== personalBadgeInfo?.id).map((badge) => {
                            return !!channelsVideos?.[badge?.id]?.length &&
                                <Section key={badge?.id}
                                         badge={badge}
                                         list={channelsVideos?.[badge?.id]}/>
                        })}
                        {!!noChannelList?.length && <Section badge={{name: t('no_channels')}}
                                                             list={noChannelList}/>}
                    </div>
                }
                {hasPermission('user_calendars_read') && isFetchingVideos !== ApiStatus.INITIAL && <Calendar/>}
            </div>
        </div>
    )


}

const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        'isMobile',
        'channels',
        'videosList',
        'isFullWidth',
        'userRoles'
    ])
};

const mapDispatchToProps = {
    GetVideos,
    GetVideosSearch,
    ChangeHeaderTitle,
    GetPersons,
    ClearVideoList,
    getChains
};

export default connect(mapStateToProps, mapDispatchToProps)(Channels);

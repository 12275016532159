// Import packages
import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";

// Import assets
import './update-video-modal.scss';

//Import components
import {Modal} from "react-bootstrap";

//Import utils
import {getPropsFromState} from "../../../redux/mapStateToProps";
import {useTranslation} from "react-i18next";
import {CloseIcon} from "../../../image";
import {
    GetChannels,
    DeleteChannel,
    UpdateVideoChannel,
    AddVideoDescription,
    AddChannel,
    UpdateChannel,
    UpdateLink
} from "../../../redux/actions";
import LinkContent from "../../../pages/ManageData/ManageVideo/LinkContent/LinkContent";
import ChannelsUpdatingList from "../../features/ChannelsUpdatingList/ChannelsUpdatingList";
import ChannelsModal from "../channelModal/ChannelsModal";

function UpdateVideoModal(props) {
    const {showModal, onHide, video} = props;
    const {t} = useTranslation();

    const [description, setDescription] = useState('');
    const [checkedChannels, setCheckedChannels] = useState(null);
    const [linkData, setLinkData] = useState(null);
    const [openCreate, setOpenCreate] = useState(false);
    const [showChannelModal, setShowChannelModal] = useState(false);
    const [editingChannel, setEditingChannel] = useState(false);


    useEffect(() => {
        props.GetChannels()
        setDescription(video?.description)
    }, [])

    function closeModal() {
        setDescription('')
        setLinkData(null)
        onHide()
    }

    function handleHideModal(isHide) {
        console.log(isHide);
    }

    function saveVideoEdith() {
        description && props.AddVideoDescription(video.id, description)

        if (checkedChannels) {
            const data = {
                channels: Object.values(checkedChannels)
            }
            props.UpdateVideoChannel(video.id, data)
        }
        linkData && props.UpdateLink(video?.link?.id, linkData, video?.id)

        setDescription('')
        closeModal()
    }

    const handleCheckedData = (channels) => {
        setCheckedChannels(channels)
    };
    const handleLinkData = (link) => {
        setLinkData(link)
    };

    function closeChannelsModal() {
        setShowChannelModal(false)
        // setEditingChannel('')
        // handleHideModal(false)
        setOpenCreate(false)
    }

    return <Modal
        show={showModal}
        onHide={onHide}
        centered={true}
        onClick={(e) => {
            e.stopPropagation()
            e.preventDefault()
        }}
        contentClassName={`update-video ${!showChannelModal ? '' : 'hide'}`}
        onWheel={(e)=>e.stopPropagation()}
    >
        <div className="update-video-header">
            <span>{t('edit_video')}</span>
            <CloseIcon onClick={onHide}/>
        </div>

        <div className="update-video-content">

            <div className="video_title">{video?.description}</div>

            <input
                onChange={(e) => setDescription(e.target.value)}
                value={description}
                name="description"
                className="description-input"
                placeholder={t('input_title')}
                type="text"
            />

            <ChannelsUpdatingList video={video}
                                  // onHide={handleCloseChannelsMenu}
                                  setEditingChannel={setEditingChannel}
                                  setShowChannelModal={setShowChannelModal}
                                  setOpenCreate={setOpenCreate}
                                  handleCheckedData={handleCheckedData}
                                  handleHideModal={handleHideModal}
                                />
            <div className="link-part">
                <div className="title">{t('profile_link')}</div>
                <LinkContent hideTitle={true}
                             link={video?.link}
                             handleLinkData={handleLinkData}/>
            </div>

        </div>

        <div className="update-video-buttons">
            <button className={`close_btn`}
                    onClick={closeModal}
            >
                {t('button_cancel')}
            </button>

            <button className={`save_btn`}
                // disabled={!disabledBtn}
                    onClick={saveVideoEdith}
            >
                {t('button_save')}
            </button>
        </div>
        {showChannelModal && <ChannelsModal showModal={showChannelModal}
                                            channel={!openCreate && editingChannel}
                                            createChannel={openCreate}
                                            onHide={closeChannelsModal}
        />}
    </Modal>
}


const mapStateToProps = (state) => {
    return getPropsFromState(state, ['channels'])
};

const mapDispatchToProps = {
    UpdateChannel,
    GetChannels,
    DeleteChannel,
    UpdateVideoChannel,
    AddVideoDescription,
    AddChannel,
    UpdateLink
};

export default connect(mapStateToProps, mapDispatchToProps)(UpdateVideoModal);

import React, {useState} from 'react';
import './ChannelsList.scss';
import {Popover} from "antd";
import {Accordion, useAccordionButton} from "react-bootstrap";
import {ArrowLeftIcon, DeletingIcon, EllipsisIcon} from "../../../../../image";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {DeleteChannel, GetChannels} from "../../../../../redux/actions";
import {ROUTERS_MAP} from "../../../../../utils/routes";
import {history} from "../../../../../utils/history";
import {hasPermission} from "../../../../../utils/helperFunctions";
import {PERMISSION} from "../../../../../utils/permission";


const tagColors = ['#4F40F7', '#2877D4', '#F7404B', '#CB43C6', '#CB7443'];

const ChannelsList = ({selectedChannel, selectedChannelId}) => {
	const {t} = useTranslation();
	const channels = useSelector(state => state.channels?.channels);
	const privateChannels = channels.filter((item) => item?.is_private);
	const publicChannels = channels.filter((item) => !item?.is_private);
	const dispatch = useDispatch();
	const [open, setOpen] = useState(false);
	const videos = useSelector(state => state.videos?.videosList?.results || [])

	function CustomToggle({children, eventKey}) {
		const decoratedOnClick = useAccordionButton(eventKey);

		return (
			<ArrowLeftIcon
				className="channel-item__header--arrow"
				onClick={decoratedOnClick}
			/>
		);
	}

	const deleteChannel = async (id) => {
		try {
			setOpen(false);
			await dispatch(DeleteChannel(id));
			const channels = await dispatch(GetChannels());
			selectedChannel(channels.filter((item) => !item?.is_private)?.[0]?.id)
		} catch (e) {
			console.error(e)
		}
	}

	function handleOpenChange(newOpen) {
		setOpen(newOpen);
	}

	function navigateTo(data) {
		history.push({
			pathname: ROUTERS_MAP.CHANNEL + `/${data?.id}`,
			state: {
				routs: [
					{
						path: ROUTERS_MAP.MANAGE_DATA,
						name: t('menu_item_manage_data')
					},
					{
						path: ROUTERS_MAP.CHANNELS,
						name: t('menu_item_channels')
					},
					{
						path: ROUTERS_MAP.CHANNEL + `/${data?.id}`,
						name: data?.name
					}
				]
			}

		})
	}

	const renderAccordionChannel = ({data, index}) => {
		const countVideos = videos.reduce((count, item) => {
			if(item?.channels.includes(data?.id)) {
				return count + 1
			}
			return count
		}, 0)

		return (
			<Accordion
				onClick={() => selectedChannel(data?.id)}
				className={`${selectedChannelId === data?.id ? 'active' : ''}`}
			>
				<Accordion.Item eventKey={index}>
					<div
						className={`channel-item__header`}
					>
						<div
							onClick={(e) => {
								navigateTo(data);
								e.stopPropagation()
							}}
							className={`channel-item__header--badge`}
							style={{
								backgroundColor: data?.is_private ? 'rgba(26, 187, 120, 0.10)'
									: tagColors[index >= 5 ? index % 5 : index] + '14',
								color: data?.is_private ? "#1ABB78" : tagColors[index >= 5 ? index % 5 : index],
								border: data?.is_private ? '0.6px solid #1ABB78' : '0'
							}}
						>
							{data?.name}
						</div>
						{/*<CustomToggle eventKey={index} className="channel-item__header--arrow"/>*/}
						<span className="channel-item__header--count">{countVideos} {t('video')}</span>
						{(hasPermission(PERMISSION.CHANNELS_UPDATE) || hasPermission(PERMISSION.CHANNELS_DELETE)) ?
							<Popover
								arrow={false}
								onOpenChange={handleOpenChange}
								trigger='click'
								placement="bottomLeft"
								overlayClassName='popover_edit-menu'
								content={
									<>
										{hasPermission(PERMISSION.CHANNELS_DELETE) ?
											<div
												className={'popover_edit-menu--item delete'}
												onClick={(e) => {
													e.stopPropagation();
													deleteChannel(data?.id)
												}}
											>
												<DeletingIcon className={''}/> {t('button_delete')}
											</div>
											: null}
									</>
								}
							>
								<div className="popover_edit-menu--settings" onClick={(e) => e.stopPropagation()}>
									<EllipsisIcon/>
								</div>
							</Popover>
							: null}
					</div>
					<Accordion.Body>

					</Accordion.Body>
				</Accordion.Item>
			</Accordion>

		)
	}

	return (
		<div className="channels-list">
			<span className="channels-list--title">{t('channels_and_chains')}</span>
			<div className="channels-list__block">
				<span className="channels-list__block--title">{t('private')}</span>
				<div className="channels-list__block__list">
					{privateChannels?.map((data, index) => (
						renderAccordionChannel({data, index})
					))}
				</div>
			</div>
			<div className="channels-list__block">
				<span className="channels-list__block--title">{t('public')}</span>
				<div className="channels-list__block__list">
					{publicChannels.map((data, index) => (
						renderAccordionChannel({data, index})
					))}
				</div>
			</div>
		</div>
	);
};

export default ChannelsList;
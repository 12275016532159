import React, {useEffect, useLayoutEffect, useRef, useState} from 'react';
import dayjs from "dayjs";
import {
	ArrowTopCircleIcon,
	ChatIcon,
	CleanIcon,
	CopyIcon,
	ErrorIcon,
	OctopusIcon,
	PlusIcon,
	SearchIcon, SuccessIcon
} from "../../../../image";
import './MessageBlock.scss';
import {TABS} from "../Chat/Chat";
import {useDispatch, useSelector} from "react-redux";
import {generateMediaUrl} from "../../../../utils/generateMediaUrl";
import {Loader} from "../../../features/Loader";
import {Tooltip} from "antd";
import {GetMessagesService, SendMessageService} from "../../../../redux/actions/chatActions";
import {getTimeDifference, getTokensText, preparedMessages} from "../../helpers";
import {MarkDownText} from "../../../features/MarkDownText";
import {useTranslation} from "react-i18next";
import SourcesBlock from "../SoursecBlock/SourcesBlock";
import {Toast} from "react-bootstrap";
import {copyToClipboard} from "../../../../utils/helperFunctions";

const DefaultMessages = (i18n) => [
	{
		response_text: i18n.language === 'en'
			? "Hi! I\'m Secretopus AI bot. I can help you find key information from your conversations without the need to go through lengthy chat logs."
			: "Привет! Я AI-бот Secretopus. Я могу помочь вам найти ключевую информацию из ваших разговоров без необходимости просматривать длинные журналы чатов."
	},
	{
		response_text: i18n.language === 'en'
			? 'Currently, I can search within specific meetings. Soon, I\'ll be able to search across multiple sections!'
			: 'В настоящее время я могу выполнять поиск по конкретным собраниям. Скоро я смогу выполнять поиск по нескольким разделам!'
		,
	},
	{
		response_text: i18n.language === 'en'
			? 'To enable global search mode, simply tab the switch next to the search bar.'
			: 'Чтобы включить режим глобального поиска, просто нажмите на переключатель рядом со строкой поиска.'
		,
	}
];

const MessageBlock = (props) => {
	const {activeTab, setActiveTab, isVisibleChat} = props;
	const [text, setText] = useState('');
	const userInfo = useSelector(state => state.profile.userProfile);
	const userAvatar = useSelector(state => state.profile.userAvatar);
	const videoInfo = useSelector(state => state.videos.videoById);
	const listRef = useRef(null);
	const textAreaRef = useRef(null);
	const [isLoadingMessage, setIsLoadingMessage] = useState(false);
	const [error, setError] = useState(false);
	const dispatch = useDispatch();
	const {t, i18n} = useTranslation();
	const [messages, setMessages] = useState(preparedMessages(DefaultMessages(i18n), [], userInfo));
	const isCheckMessages = messages.length <= 3;
	const [toastInfo, setToastInfo] = useState({isShow: false, type: 'danger'});

	const changeText = (e) => {
		setText(e?.target?.value || '')
	}

	const addText = async (text) => {
		try {
			if(text) {
				setError(null);
				setText('');
				setMessages([...messages, {
					id: 100,
					isBot: false,
					text,
					date: dayjs(),
					used_db_query: false,
					author_name: `${userInfo?.last_name || ''} ${userInfo?.first_name || ''}`,
				}]);
				setIsLoadingMessage(true);
				await dispatch(SendMessageService({
					video_id: videoInfo?.id,
					request_text: text,
					clear_session: false
				}))
				const response = await dispatch(GetMessagesService(videoInfo?.id));
				setMessages(preparedMessages(DefaultMessages(i18n), response?.chat || [], userInfo));

				textAreaRef.current.style.height = '20px';
				setIsLoadingMessage(false);
			}
		} catch (e) {
			setError(e);
			setIsLoadingMessage(false)
		}
	}

	const copyMessage = (item) => {
		try {
			copyToClipboard(item.text)
			setToastInfo({
				isShow: true,
				type: 'success',
				text: t("toast_success_description")
			});
		} catch (e) {
			console.error(e)
		}
	}

	const getMessages = async () => {
		try {
			const response = await dispatch(GetMessagesService(videoInfo?.id));
			setMessages(preparedMessages(DefaultMessages(i18n), response?.chat || [], userInfo))
		} catch (e) {
			setError(e);
			console.error(e);
		}
	}

	const clearChat = async () => {
		try {
			if(!isCheckMessages) {
				await dispatch(SendMessageService({
					video_id: videoInfo?.id,
					clear_session: true
				}));
				const response = await dispatch(GetMessagesService(videoInfo?.id));
				setMessages(preparedMessages(DefaultMessages(i18n), response?.chat || [], userInfo))
			}
		} catch (e) {
			setError(e);
			console.error(e);
		}
	}

	const handleKeyDown = (e) => {
		if (e.key === 'Enter') {
			// Если нажата клавиша Enter без Shift
			if (!e.shiftKey) {
				e.preventDefault(); // Предотвращаем перенос строки
				addText(text);
			}
		}
	};

	useEffect(() => {
		function updateHeightTextArea() {
			this.style.height = '20px';
			this.style.overflow = 'hidden';
			this.style.height = this.scrollHeight + 'px';
		}

		textAreaRef.current?.addEventListener('input', updateHeightTextArea);

		return () => {
			textAreaRef.current?.removeEventListener('input', updateHeightTextArea);

		}
	}, []);

	useLayoutEffect(() => {
		if(listRef.current) {
			listRef.current.scrollTop = listRef.current.scrollHeight;
		}
	}, [messages, isLoadingMessage]);

	useEffect(() => {
		if(activeTab === TABS.MESSAGE) {
			getMessages();
		}
	}, []);

	return (
		<>
			<div className={`clean-chat ${isCheckMessages ? 'clean-chat--disabled' : ''}`}>
				<CleanIcon onClick={clearChat}/>
				<span
					onClick={clearChat}
				>
					{t('chat_clean_btn')}
				</span>
			</div>
			<div className="message-block">
				<div
					className="message-block__wrap   custom-scroll"
					ref={listRef}
				>
					<div className="message-block__list">
						{messages.map((item) => (
							<div className="message">
								{item?.isBot ?
									<div className="message__avatar-bot">
										<OctopusIcon className='message__avatar-bot--icon'/>
									</div>
									:
									<img src={generateMediaUrl(userAvatar)} alt=""/>
								}
								<div className="message__block">
									<div className="message__block_title">
										<div className="message__block_title--name">{item.author_name}</div>
										<div className="message__block_title--date">
											{item.date ? <span>{getTimeDifference(item.date, i18n)}</span> : null}
											{item?.used_db_query ? <span>&#32;&#32;&#8226;&#32;&#32;used data sourses</span> : null}
											{item?.tokens_used ? <span>&#32;&#32;&#8226;&#32;&#32;{getTokensText(item.tokens_used)}</span> : null}
										</div>
									</div>
									<MarkDownText
										className="message__block--text"
										text={item.text}
									/>
									<SourcesBlock
										messages={messages}
										listRef={listRef}
										videoInfo={videoInfo}
										item={item}
										copyMessage={copyMessage}
									/>
								</div>
							</div>
						))}
						{isLoadingMessage ?
							<div className="message">
								<div className="message__avatar-bot">
									<OctopusIcon className='message__avatar-bot--icon'/>
								</div>
								<div className="message__block">
									<div className="message__block_title">
										<div className="message__block_title--name">Secretopus</div>
										<div
											className="message__block_title--date">{i18n.language === 'ru' ? '0 мин. назад' : '0 m. ago'}</div>
									</div>
									<div className="message__block--text-loader">
										{t('chat_querying_db')}
										<Loader size={16}/>
									</div>
								</div>
							</div>
							: null}
						{error ?
							<div className="message">
								<div className="message__avatar-bot">
									<OctopusIcon className='message__avatar-bot--icon'/>
								</div>
								<div className="message__block">
									<div className="message__block_title">
										<div className="message__block_title--name">Secretopus</div>
										<div className="message__block_title--date">{getTimeDifference(dayjs(), i18n)}</div>
									</div>
									<div className="message__block--text-error">
										{t('chat_system_error')}
									</div>
								</div>
							</div>
							: null}
					</div>
				</div>
			</div>
			<div className="chat__footer">
				<Tooltip
					placement="left"
					title={t('chat_tooltip_badge_search')}
				>
					<div className='chat__tabs'>
						<div
							className={`chat__tabs-item ${activeTab === TABS.MESSAGE ? 'chat__tabs--active' : ''}`}
							onClick={() => setActiveTab(TABS.MESSAGE)}
						>
							<OctopusIcon className='chat__tabs_icon-octopus'/>
						</div>
						<div
							className={`chat__tabs-item ${activeTab === TABS.SEARCH ? 'chat__tabs--active' : ''}`}
							onClick={() => setActiveTab(TABS.SEARCH)}
						>
							<SearchIcon className='chat__tabs_icon-search'/>
						</div>
					</div>
				</Tooltip>
				<div
					className="chat-input"
					tabIndex={0}
				>
					<textarea
						ref={textAreaRef}
						value={text}
						onChange={changeText}
						onKeyDown={handleKeyDown}
						className="chat-input__input custom-scroll"
						placeholder={t('chat_message_input')}
						id={"autoTextarea"}
					/>
					<ArrowTopCircleIcon
						onClick={()=>addText(text)}
						className={`chat-input__right-icon${text ? '--active' : ''}`}/>
				</div>
			</div>
			<Toast
				className='upload_video_answer'
				onClose={() => setToastInfo({isShow: false, type: toastInfo.type})}
				show={toastInfo.isShow}
				delay={3000}
				autohide
			>
				<div className="toasblock">
					{toastInfo.type === 'danger' ? <ErrorIcon/> : <SuccessIcon/>}
					<div className="toasblock__wrap">
						<span
							className="toasblock__wrap--title">{t('toast_success_title')}</span>
						<span className="toasblock__wrap--text">{toastInfo?.text || ''}</span>
					</div>
				</div>
			</Toast>
		</>
	);
};

export default MessageBlock;
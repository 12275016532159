// Import packages
import React, {useEffect, useRef, useState} from 'react';
import {connect, useSelector} from "react-redux";
import {Link, useLocation, useParams} from "react-router-dom";
import Draggable from "react-draggable";
import {Allotment} from "allotment";

// Import assets
import './video-details.scss';
import {
    EditGreenIcon, EditIcon,
    FullScreenIcon,
    PinIcon,
    PlayerPauseIcon,
    PlayerPlayIcon, ShareIcon, SuccessIcon
} from "../../../../image";
import 'react-resizable/css/styles.css';

//Import utils
import {getPropsFromState} from "../../../../redux/mapStateToProps";
import {
    AddVideoDescription,
    ClearVideoById, CreateVideoUrl,
    DeleteProtocol,
    GetVideoById,
    UpdateProtocol,
    UpdateTranscription,
    GetChannels,
    UpdateTranscriptionAll, MarkVideo, DownloadTranscription, ReplaceTranscriptionWord, GetLink, GetPersons
} from "../../../../redux/actions";
import {generateVideoUrl} from "../../../../utils/generateMediaUrl";
import {GetProtocol, AddProtocol} from "../../../../redux/actions";
import {history} from "../../../../utils/history";

//Import Component
import Texts from "./texts/Texts";
import Protocol from "./protocol/Protocol";
import { Breadcrumb, Tab, Tabs, Toast} from "react-bootstrap";
import NewPlayer from "./new-player/NewPlayer";
import {changeTimeFormat, getTimeString} from "../../../../utils/helperFunctions";
import SearchTexts from "./search-texts/SearchTexts";
import {useTranslation} from "react-i18next";
import {ResizableBox} from 'react-resizable';
import UserInfoModal from "../Users/modals/userInfoModal/UserInfoModal";
import TextsUpdate from "./tests-update/TextsUpdate";
import UpdateVideoModal from "../../../../shared/modals/updateVideolModal/UpdateVideoModal";
import Notifications from "../../../../shared/features/notification/Notifications";
import {MarkDownText} from "../../../../shared/features/MarkDownText";
import {VIDEO_PANEL_TABS} from "./const";
import InfoTooltip from "../Profile/tooltip";
import ChatBlock from "../../../../shared/chat/ChatBlock";
import {ROUTERS_MAP} from "../../../../utils/routes";

const headerHeight = 85

const initialState = {
    search: '',
    replace: '',
}

function VideoDetails(props) {
    const {videoById, protocolList, personsList, token} = props
    const params = useParams();
    const location = useLocation();
    const videoPlayer = useRef();
    const videoHeight = useRef();
    const playerHeight = useRef();
    const pageRef = useRef();
    const containerRef = useRef();
    const isAuth = useSelector(state => Boolean(state.auth?.token))

    const {t, i18n} = useTranslation();
    const currentLanguage = i18n.language;

    const [videoTimelineScale, setVideoTimelineScale] = useState({
        scaleX: 1,
        minScaleX: 0.5,
        maxScaleX: 5,
        stepScaleX: 0.1,
    });
    const [isPlayed, setIsPlayed] = useState(false)
    const [videoPlayerCurrentTime, setVideoPlayerCurrentTime] = useState(0)
    const [currentVideoId, setCurrentVideoId] = useState(0)
    const [videoPlayerDuration, setVideoPlayerDuration] = useState(0)
    const [videoPlayerCurrentTimeScroll, setVideoPlayerCurrentTimeScroll] = useState(0)
    const [showSmallVideo, setShowSmallVideo] = useState(false);
    const [pinVideo, setPinVideo] = useState(false);
    const [pinSmallVideo, setPinSmallVideo] = useState(false);
    const [showRenameModal, setShowRenameModal] = useState(false);
    const [videoPosition, setVideoPosition] = useState({x: 0, y: 0});
    const [showAI, setShowAI] = useState(VIDEO_PANEL_TABS.TASKS);
    const [searchText, setSearchText] = useState('');
    const [foundedTexts, setFoundedTexts] = useState([]);
    const [activeTextIndex, setActiveTextIndex] = useState(0);
    const [coped, setCopied] = useState(false);
    const [size, setSize] = useState({
        width: 260,
        height: 140,
    });
    const [showModal, setShowModal] = useState(false)
    const [currentUser, setCurrentUser] = useState(null)
    const [editAll, setEditAll] = useState(false)
    const [textInfo, setTextInfo] = useState(initialState)
    const [key, setKey] = useState(VIDEO_PANEL_TABS.AI);
    const haveChanges = searchText && textInfo.replace;
    const [page, setPage] = useState(-1);
    const clockRef = useRef(0);
    const showRenameModalRef = useRef(showRenameModal);
    const showModalRef = useRef(showModal);
    const pinSmallVideoRef = useRef(pinSmallVideo);
    const pinVideoRef = useRef(pinVideo);
    const notificationsRef = useRef();
    const blockText = document.querySelector('#block-details-texts');
    const userProfile = useSelector(state => state.profile.userProfile);

    const rect = blockText?.getBoundingClientRect();

    useEffect(() => {
        showRenameModalRef.current = showRenameModal;
    }, [showRenameModal]);

    useEffect(() => {
        showModalRef.current = showModal;
    }, [showModal]);

    useEffect(() => {
        pinSmallVideoRef.current = pinSmallVideo;
    }, [pinSmallVideo]);

    useEffect(() => {
        pinVideoRef.current = pinVideo;
    }, [pinVideo]);

    useEffect(() => {
        const handleClick = (event) => {
            const summaryElement = event.target.closest('summary');
            if (summaryElement) {
                const detailsElement = summaryElement.closest('details');
                if (detailsElement) {
                    if (detailsElement.open) {
                        summaryElement.textContent = 'Expand';
                    }else{
                        summaryElement.textContent = 'Collapse';
                    }
                }
            }
        };

        const currentContainer = notificationsRef?.current;
        currentContainer?.addEventListener('click', handleClick);

        return () => {
            currentContainer?.removeEventListener('click', handleClick);
        };
    }, [containerRef]);

    const handleWheel = (event) => {
        if(!showRenameModalRef.current && !showRenameModalRef.current) {
            if(clockRef.current === 0) {
                clockRef.current = 1;
                const blockText = document.querySelector('#block-details-texts');

                const go = event.deltaY < 0 ? -1 : 1;
                setPage(go);
                const rect = containerRef.current.getBoundingClientRect();
                const offset = window.scrollY + rect.top - 262;

                setVideoPosition({x:  0, y: 0})
                    setShowSmallVideo(event.deltaY > 0);
                    window.scrollTo({
                        top: event.deltaY > 0 ? offset : 0,
                        behavior: 'smooth',
                    });

                setTimeout(() => {
                    clockRef.current = 0;
                }, 1000);
            }
        }
    };


    const onScroll = () =>{
        if (containerRef.current) {
            const rect = containerRef.current.getBoundingClientRect();
            const offset = window.scrollY + rect.top - 262;
            setShowSmallVideo(true);
            setVideoPosition({x: 0 , y: 0})
            setPage(1);
            window.scrollTo({
                top: offset,
                behavior: 'smooth'
            });
        }
    }

    useEffect(()=>{
        document.body.style.overflow = "auto hidden";

        return () =>{
            document.body.style.overflow = "auto";
        }
    },[])

    useEffect(() => {
        videoHeight.current = ((videoPlayer?.current?.offsetHeight || 0)) + headerHeight
        window.addEventListener('wheel', handleWheel);

        return () => {
            window?.addEventListener('wheel', handleWheel);
        };
    }, []);

    useEffect(() => {
        const urlParams = new URLSearchParams(location.search);
        const paramsTime = urlParams.get('t')
        const paramsUuid = urlParams.get('uuid')
        props.ClearVideoById()
        props.GetVideoById(params.videoId, paramsUuid).then(() => {
            videoHeight.current = (videoPlayer?.current?.offsetHeight || 0) + headerHeight
        })
        props.GetProtocol(params.videoId, paramsUuid)

        if (params?.time) {
            setVideoPlayerCurrentTime(params?.time)
            changeVideoTime(params?.time)
        }
        if (paramsTime) {
            setVideoPlayerCurrentTime(paramsTime)
            changeVideoTime(paramsTime)
        }
        if (location?.state?.openEdit) {
            setShowRenameModal(true)
        }
        return () => {
            props.ClearVideoById()
        };
    }, [params.videoId, params?.time])

    useEffect(() => {
        // For inject to Player Component (for timeline)
        setInterval(() => {
            if (videoPlayer && videoPlayer?.current?.currentTime && videoPlayer?.current?.duration) {
                setVideoPlayerCurrentTime(videoPlayer?.current?.currentTime)
                setVideoPlayerDuration(videoPlayer?.current?.duration)
                // For timeupdate while pause
                // videoPlayer.current?.addEventListener('timeupdate', function () {
                //     setVideoPlayerCurrentTime(this.currentTime)
                //     setVideoPlayerDuration(this.duration)
                // }, false);
            }
        }, 25);
    }, [])


    useEffect(() => {
        if(!videoById?.task_list.length && videoById?.insight_list.length){
            setShowAI(VIDEO_PANEL_TABS.INSIGHTS)
        }
    }, [videoById])

    useEffect(() => {
        if (coped) {
            setTimeout(() => {
                setCopied(false)
            }, 3000)
        }
    }, [coped])

    const getTime = (value) => {
        let secNum = parseInt(value, 10) || 0;
        let hours = Math.floor(secNum / 3600);
        let minutes = Math.floor((secNum - hours * 3600) / 60);
        let seconds = secNum - hours * 3600 - minutes * 60;

        if (hours < 10) {
            hours = "0" + hours;
        }
        if (minutes < 10) {
            minutes = "0" + minutes;
        }
        if (seconds < 10) {
            seconds = "0" + seconds;
        }

        const scaleX = parseFloat(videoTimelineScale.scaleX);
        if (scaleX > 0.4) {
            return hours + ":" + minutes + ":" + seconds;
        }
        if (0.2 < scaleX && scaleX <= 0.4) {
            return hours + ":" + minutes + ":" + seconds;
        } else {
            return hours + ":" + minutes + ":" + seconds;
        }
    }

    /* Change video time */
    const changeVideoTime = (time, id) => {
        setCurrentVideoId(id);
        setVideoPlayerCurrentTime(Number(time || 0))
        setVideoPlayerCurrentTimeScroll(time || 0)
        videoPlayer.current && (videoPlayer.current.currentTime = time || 0)
    }

    const togglePin = () => {
        if((showSmallVideo || pinSmallVideo)){
            setVideoPosition({x : 0, y: 0})
            setPinSmallVideo(!pinSmallVideo)
        } else{
            setPinVideo(!pinVideo)
        }
    };

    const toggleActiveTextIndex = (index) => {
        setActiveTextIndex(index);
        const currentIndex = index - 1 ;
        if(VIDEO_PANEL_TABS.TEXTS === key){
            changeVideoTime(videoById?.texts?.[foundedTexts[currentIndex]]?.start_time);
        }else if(VIDEO_PANEL_TABS.AI === key){
            const arr = [...videoById?.task_list, ...videoById?.insight_list];
            changeVideoTime(arr?.[foundedTexts[currentIndex]]?.start_time,arr?.[foundedTexts[currentIndex]]?.id);
        }
    };

    function openRenameModal() {
        if (token) {
            setShowRenameModal(true)
        } else {
            history.push('/');
        }
    }

    function closeRenameModal() {
        setShowRenameModal(false)
    }

    const drugHandler = (e, data) => {
        e.stopPropagation()
        setVideoPosition({
            x: data.x,
            y: data.y,
        })
        // setTimeout(() => {
        //     setShowControls(true);
        // }, 200)
    }

    const drugStart = (e) => {
        e.stopPropagation();
        // setShowControls(false);
    }
    const changeVideoSpeed = (videoSpeed = 1) => {
        videoPlayer.current.playbackRate = videoSpeed;
    };

    // const toggleAiNotes = (key) => {
    //     setActiveTextIndex(0);
    //     setShowAI(key === showAI ? '' : key)
    // };

    function CopyToast() {
        return <Toast show={coped} onClose={() => setCopied(false)} className={'upload_video_answer'}>
                <div className="toasblock">
                    <SuccessIcon/>
                    <div className="toasblock__wrap">
                        <span className="toasblock__wrap--title">{t('toast_success_title')}</span>
                        <span className="toasblock__wrap--text">{t('link_copied_to_clipboard')}</span>
                    </div>
                </div>
        </Toast>
    }

    const handleResize = (event, {size}) => {
        setSize(size);
    };
    function toggleUserModal(person) {
        if(person && (userProfile?.attached_person?.id === (person.id?.id || person?.id?.user_id))){
            history.push(ROUTERS_MAP.PROFILE);
        }else{
            setShowModal(!showModal)
            setCurrentUser(person)
        }
    }

    function getInputValue(e) {
        const {name, value} = e.target

        setTextInfo({
            ...textInfo,
            [name]: value
        })
    }

    function onClose() {
        setEditAll(false)
        setTextInfo(initialState)
        setSearchText('')
        setFoundedTexts([])
        setActiveTextIndex(0)
    }
    function getPreviewTags  ()  {
        return i18n.language === 'ru'
            ? ['Идеи', 'Обратная связь', 'Обсуждение сроков', 'Обсуждение бюджета', 'Ошибки']
            : ['Ideas', 'Feedback','Deadline Discussion', 'Budget Discussion' ,'Bugs']
    }

    function changeAll() {
        props.ReplaceTranscriptionWord({
            video_id: videoById?.id,
            search: searchText,
            replace: textInfo?.replace
        })

        const urlParams = new URLSearchParams(location.search);
        const paramsUuid = urlParams.get('uuid')

        props.GetVideoById(videoById?.id, paramsUuid)

        onClose()
    }

    function replaceNthOccurrence(str, word, n, replacement) {
        let index = -1;
        for (let i = 0; i < n; i++) {
            index = str.indexOf(word, index + 1);
            if (index === -1) break;
        }

        if (index !== -1) {
            return str.substring(0, index) + replacement + str.substring(index + word.length);
        } else {
            return str;
        }
    }


    function changeText() {
        const item = videoById?.texts?.[foundedTexts[activeTextIndex]]
        let count = 0
        foundedTexts.forEach((i, index) => {
            if (index < activeTextIndex && i === foundedTexts[activeTextIndex]) {
                count++
            }
        })

        let result = replaceNthOccurrence(item?.text, searchText, count, textInfo?.replace);

        props.UpdateTranscription(
            item?.id,
            undefined, undefined,
            {
                text: count ? result : item?.text?.replace(searchText, textInfo?.replace),
                start_time: Number(item?.start_time),
                end_time: Number(item?.end_time)
            }).finally(() => {
            const urlParams = new URLSearchParams(location.search);
            const paramsUuid = urlParams.get('uuid')

            props.GetVideoById(videoById?.id, paramsUuid)
        })


        onClose()
    }

    const toggleTabs = (key) => {
        setKey(key);
        setActiveTextIndex(0);
        setEditAll(false);
        if(key === VIDEO_PANEL_TABS.AI){
            setFoundedTexts([])
        }
    }

    const addProtocol = async (data, protocolIndex) => {
       try{
           const urlParams = new URLSearchParams(location.search);
           const paramsUuid = urlParams.get('uuid')
           await props.AddProtocol(data, protocolIndex)
           await props.GetProtocol(params.videoId, paramsUuid);
       }catch (error) {
           console.error(error)
       }
    }

    const updateProtocol = async (id, data) => {
       try{
           const urlParams = new URLSearchParams(location.search);
           const paramsUuid = urlParams.get('uuid')
           await props.UpdateProtocol(id, data)
           await props.GetProtocol(params.videoId, paramsUuid);
       }catch (error) {
           console.error(error)
       }
    }

    return (
        (videoById?.url || videoById?.url_s3) && <div
            className={`video_details ${((showSmallVideo && !pinVideo) || pinSmallVideo)
                ? 'small_video' : ''} ${props.isFullWidth ? 'no-full' : 'full'}`} ref={pageRef}>
            <div className="pages">
                <div
                  onClick={()=>{
                      setVideoPosition({x: 0, y: 0})
                      setShowSmallVideo(false);
                      setPage(-1);
                      window.scrollTo({
                          top: 0,
                          behavior: 'smooth'
                      });
                  }}
                  className={`${page === -1 ? 'active' : ''}`}/>
                <div
                  onClick={()=>{
                      onScroll();
                  }}
                  className={`${page === 1 ? 'active' : ''}`}/>
            </div>
            <Breadcrumb>
                {
                    !!location?.state?.routs?.length && location?.state?.routs?.map((item, index) => {
                        return <Breadcrumb.Item key={item?.name} active={item?.active}>
                            {
                                item?.id ? <div className={'breadcrumb-item'}
                                                onClick={() => toggleUserModal(item)}>
                                    {item?.name}
                                </div> :
                                  <>
                                  {item?.isBack ?
                                    <div className={'breadcrumb-item'}
                                         onClick={() => history.goBack()}>
                                        {item?.name}
                                    </div>
                                    :
                                    <Link to={{
                                        pathname: item?.path,
                                        state: {
                                            routs: location?.state?.routs.slice(0, index + 1)
                                        }
                                    }}>{item?.name}</Link>
                                  }
                                  </>
                            }
                        </Breadcrumb.Item>
                    })
                }
                <Breadcrumb.Item active>
                    <InfoTooltip
                      className="breadcrumb_name"
                      elem={
                        <span>{videoById?.description}</span>
                    }
                                 title={videoById?.description}
                    />

                </Breadcrumb.Item>
            </Breadcrumb>
            <div className="video_details__header">
                <div className="video_details__header__description">
                    <InfoTooltip elem={
                        <span>{videoById?.description}</span>
                    }
                    title={videoById?.description}
                    />

                    <div className="video_details__header__date">
                        {changeTimeFormat(videoById?.creation_time, currentLanguage)}
                    </div>
                </div>
                <div className="video_details__header__buttons">
                    <button className="video_details__header__edit_btn" onClick={openRenameModal}>
                        <EditGreenIcon/>
                        {t('button_edit')}
                    </button>
                    <button className="video_details__header__share_btn" onClick={() => {
                        props.GetLink({
                            video_id: videoById?.id
                        })
                        .then((res) => {
                            navigator.clipboard.writeText(res.link.url);
                            setCopied(true)
                        })
                        // props.CreateVideoUrl({
                        //     base_url: `${window.location.origin + location.pathname}`,
                        //     video_id: videoById?.id
                        // }).then((res) => {
                        //     navigator.clipboard.writeText(res.url);
                        //     setCopied(true)
                        // })
                    }}>
                        <ShareIcon/>
                        {t('button_share')}
                    </button>
                    <Notifications/>
                </div>
            </div>
            <div className="video_details__top">
                <Draggable disabled={!showSmallVideo}
                           position={videoPosition}
                           defaultClassName={'draggable-video'}
                           onStart={drugStart}
                           onStop={drugHandler}>
                    <ResizableBox
                        width={(showSmallVideo && !pinVideo) || pinSmallVideo ? size.width : pageRef?.current?.offsetWidth - 380}
                        height={(showSmallVideo && !pinVideo) || pinSmallVideo ? size.height : 0}
                        onResize={handleResize}
                    >
                        <div
                            className={`video_details__video ${(showSmallVideo && !pinVideo) || pinSmallVideo ? 'resizable' : ''}`}>
                            <div className="video-wrapper">
                                {(videoById?.url || videoById?.url_s3) &&
                                    <video controls={false}
                                           disablePictureInPicture={true}
                                           ref={videoPlayer}>
                                        <source
                                            src={videoById?.url_s3 ? videoById?.url_s3 : generateVideoUrl(videoById?.url)}
                                            type="video/mp4"/>
                                    </video>}
                                <button
                                    className={`pin_video ${pinVideo ? 'pined' : ''} ${pinSmallVideo ? 'pined' : ''}`}
                                    onClick={togglePin}>
                                    <span/>
                                    <PinIcon/>
                                </button>
                                <button className="full_screen" onClick={() => {
                                    videoPlayer?.current?.requestFullscreen()
                                }}>
                                    <span/>
                                    <FullScreenIcon/>
                                </button>
                                {((showSmallVideo && !pinVideo) || pinSmallVideo) && <>
                                    {
                                        isPlayed ? <PlayerPauseIcon
                                            className={'small_video_play'}
                                            onClick={() => {
                                                setIsPlayed(false)
                                                videoPlayer.current?.pause();
                                            }}/> : <PlayerPlayIcon
                                            className={'small_video_play'}
                                            onClick={() => {
                                                setIsPlayed(true)
                                                videoPlayer.current?.play();
                                            }}/>
                                    }
                                    <div className="duration">
                                        <span>{getTimeString(videoPlayerCurrentTime)}</span>
                                        &nbsp;/&nbsp;
                                        <span>{getTimeString(videoById?.duration)}</span>
                                    </div>
                                </>}
                            </div>
                        </div>
                    </ResizableBox>
                </Draggable>


                <div className="notification-area" onWheel={(e) => e.stopPropagation()} ref={notificationsRef}>
                     <div className="area-top">
                        <div className="notification-area__header">
                            {t('notification_title')}

                            {/*<CloseIcon/>*/}
                        </div>
                        {/*<div className="notification-area__date">*/}
                        {/*    <span>12 Ян 2023</span>&nbsp;.&nbsp;*/}
                        {/*    <span>4:30 pm</span>&nbsp;.&nbsp;*/}
                        {/*    <span>8 {t('minute')} 3 {t('second')}</span>*/}
                        {/*</div>*/}
                        <div className="notification-area__content">
                             <MarkDownText
                                className="notification-item"
                                text={`###### TL;DR\n\n` + videoById?.summary}
                             />
                        </div>
                    </div>
                    {/*<div className="area-bottom">*/}
                    {/*    <NotificationCard/>*/}
                    {/*    <NotificationCard/>*/}
                    {/*</div>*/}
                    <div className="preview">
                        <p className="preview--title">Coming Soon: Smart tags</p>
                        <p className="preview--description">
                            Smart Tags is a feature that automatically assigns one or
                            several unique tags to each call based on what occurred during the meeting.
                        </p>
                        <div className="preview__tags">
                            {getPreviewTags().map((item)=>(
                                <div className="preview__tags--tag">{item}</div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>

            <NewPlayer videoPlayer={videoPlayer}
                       getTime={getTime}
                       playerHeight={playerHeight}
                       changeVideoSpeed={changeVideoSpeed}
                       changeVideoTime={changeVideoTime}
                       showSmallVideo={showSmallVideo}
                       isPlayed={isPlayed}
                       setIsPlayed={setIsPlayed}
                       videoTimelineScale={videoTimelineScale}
                       setVideoTimelineScale={setVideoTimelineScale}
                       videoPlayerDuration={videoPlayerDuration}
                       videoPlayerCurrentTime={videoPlayerCurrentTime}
                       videoPlayerCurrentTimeScroll={videoPlayerCurrentTimeScroll}
                       setVideoPlayerCurrentTimeScroll={setVideoPlayerCurrentTimeScroll}
                       protocolList={protocolList}
                       onScroll={onScroll}
            />
            <div ref={containerRef}/>
            <div className="video_details__texts" id="block-details-texts">
                <Allotment separator={false}>
                    <Allotment.Pane minSize={446}>
                        <Protocol texts={videoById?.texts}
                                  protocolList={protocolList}
                                  changeVideoTime={changeVideoTime}
                                  GetLink={props.GetLink}
                                  videoId={videoById?.id}
                                  videoPersons={videoById.persons}
                                  company={videoById?.company}
                                  personsList={personsList}
                                  setCopied={setCopied}
                                  videoPlayerCurrentTime={videoPlayerCurrentTime}
                                  getTime={getTime}
                                  DeleteProtocol={props.DeleteProtocol}
                                  UpdateProtocol={updateProtocol}
                                  AddProtocol={addProtocol}
                                  MarkVideo={props.MarkVideo}
                                  currentVideoId={currentVideoId}
                        />
                    </Allotment.Pane>
                    <Allotment.Pane minSize={570} >
                        <div className="texts-header">
                            <div className="texts-header__title">{t('AI_panel_title')}</div>
                            {!editAll && <SearchTexts searchText={searchText}
                                                      setSearchText={setSearchText}
                                                      foundedTexts={foundedTexts}
                                                      activeTextIndex={activeTextIndex}
                                                      toggleActiveTextIndex={toggleActiveTextIndex}
                            />}
                        </div>


                        <Tabs id="controlled-tab-example"
                              activeKey={key} onSelect={(k) => toggleTabs(k)}
                              className={'text-wrapper'}
                        >
                            <Tab
                              eventKey={VIDEO_PANEL_TABS.AI} title={t('AI_notes_title')}>
                                {!![...videoById?.task_list,...videoById?.insight_list].length &&
                                  <div className="texts__wrap">
                                          <div className={'texts__title'}>{t('tasks_title')}</div>
                                          <Texts texts={[...videoById?.task_list,...videoById?.insight_list]}
                                                 countTask={[...videoById?.task_list].length}
                                                 activeTextIndex={activeTextIndex}
                                                 getTime={getTime}
                                                 changeVideoTime={changeVideoTime}
                                                 searchText={searchText}
                                                 setFoundedTexts={setFoundedTexts}
                                                 foundedTexts={foundedTexts}
                                                 AddProtocol={addProtocol}
                                                 UpdateTranscription={props.UpdateTranscription}
                                                 ReplaceTranscriptionWord={props.ReplaceTranscriptionWord}
                                                 videoId={videoById?.id}
                                                 videoPersons={videoById.persons}
                                                 token={token}
                                                 isPlayed={isPlayed}
                                                 pinVideo={pinVideo}
                                                 noteId={videoById?.note?.id}
                                                 noteLength={videoById?.note?.note?.length}
                                                 videoPlayerCurrentTime={videoPlayerCurrentTime}
                                                 isAI={true}
                                                 MarkVideo={props.MarkVideo}
                                                 activeTabs={key}
                                                 tabType={VIDEO_PANEL_TABS.AI}
                                                 currentVideoId={currentVideoId}
                                                 GetVideoById={props.GetVideoById}
                                                 lastProtocolId={protocolList[protocolList.length - 1]?.id}
                                          />
                                  </div>
                                }
                            </Tab>

                            <Tab eventKey={VIDEO_PANEL_TABS.SUMMERY} title={<>
                                {t('summary_title')}
                            </>}>

                                <Texts texts={videoById?.chapter_list}
                                       activeTextIndex={activeTextIndex}
                                       searchText={searchText}
                                       setFoundedTexts={setFoundedTexts}
                                       getTime={getTime}
                                       changeVideoTime={changeVideoTime}
                                       AddProtocol={addProtocol}
                                       GetVideoById={props.GetVideoById}
                                       UpdateTranscription={props.UpdateTranscription}
                                       ReplaceTranscriptionWord={props.ReplaceTranscriptionWord}
                                       videoId={videoById?.id}
                                       videoPersons={videoById.persons}
                                       token={token}
                                       isPlayed={isPlayed}
                                       pinVideo={pinVideo}
                                       noteId={videoById?.note?.id}
                                       lastProtocolId={protocolList[protocolList.length - 1]?.id}
                                       noteLength={videoById?.note?.note?.length}
                                       videoPlayerCurrentTime={videoPlayerCurrentTime}
                                       MarkVideo={props.MarkVideo}
                                       activeTabs={key}
                                       tabType={VIDEO_PANEL_TABS.SUMMERY}
                                       currentVideoId={currentVideoId}
                                       foundedTexts={foundedTexts}
                                />
                            </Tab>

                            <Tab eventKey={VIDEO_PANEL_TABS.TEXTS} title={<>
                                {t('texts_title')}
                                <TextsUpdate DownloadTranscription={props.DownloadTranscription}
                                             ReplaceTranscriptionWord={props.ReplaceTranscriptionWord}
                                             videoId={videoById?.id}
                                             videoName={videoById?.description}
                                             setEditAll={setEditAll}
                                             activeTab={key}
                                />
                            </>}>

                                {
                                  editAll && <div className="edit-texts-wrapper">
                                      <SearchTexts searchText={searchText}
                                                   setSearchText={setSearchText}
                                                   foundedTexts={foundedTexts}
                                                   activeTextIndex={activeTextIndex}
                                                   toggleActiveTextIndex={toggleActiveTextIndex}
                                      />
                                      <div className="input_group">
                                          <EditIcon/>
                                          <input onChange={getInputValue}
                                                 value={textInfo.replace}
                                                 name="replace"
                                                 placeholder={t('replace_with')}
                                                 type="text"
                                          />
                                      </div>

                                      <div className="buttons">
                                          <button className={`close_btn`}
                                                  onClick={onClose}
                                          >
                                              {t('button_cancel')}
                                          </button>

                                          <button className={`save_btn ${!haveChanges ? 'disabled' : ''}`}
                                                  disabled={!haveChanges}
                                                  onClick={changeText}
                                          >
                                              {t('replace')}
                                          </button>
                                          <button className={`save_btn ${!haveChanges ? 'disabled' : ''}`}
                                                  disabled={!haveChanges}
                                                  onClick={changeAll}
                                          >
                                              {t('replace_all')}
                                          </button>
                                      </div>
                                  </div>
                                }

                                <Texts texts={videoById?.texts}
                                       activeTextIndex={activeTextIndex}
                                       searchText={searchText}
                                       setFoundedTexts={setFoundedTexts}
                                       foundedTexts={foundedTexts}
                                       getTime={getTime}
                                       changeVideoTime={changeVideoTime}
                                       AddProtocol={addProtocol}
                                       GetVideoById={props.GetVideoById}
                                       UpdateTranscription={props.UpdateTranscription}
                                       ReplaceTranscriptionWord={props.ReplaceTranscriptionWord}
                                       videoId={videoById?.id}
                                       videoPersons={videoById.persons}
                                       token={token}
                                       isPlayed={isPlayed}
                                       pinVideo={pinVideo}
                                       noteId={videoById?.note?.id}
                                       lastProtocolId={protocolList[protocolList.length - 1]?.id}
                                       noteLength={videoById?.note?.note?.length}
                                       videoPlayerCurrentTime={videoPlayerCurrentTime}
                                       MarkVideo={props.MarkVideo}
                                       activeTabs={key}
                                       tabType={VIDEO_PANEL_TABS.TEXTS}
                                       currentVideoId={currentVideoId}
                                       editAll={editAll}
                                />
                            </Tab>
                        </Tabs>
                    </Allotment.Pane>
                </Allotment>
            </div>

            {CopyToast()}
            {showModal && <UserInfoModal show={showModal}
                                         closeModal={toggleUserModal}
                                         user={currentUser}
                                         />}
            {showRenameModal && <UpdateVideoModal
              showModal={showRenameModal}
                                                  onHide={closeRenameModal}
                                                  video={videoById}/>}
          {isAuth ? <ChatBlock/> : null}
        </div>
    );
}

const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        'videoById',
        'token',
        'protocolList',
        'channels',
        'personsList',
        'isFullWidth',
    ])
};

const mapDispatchToProps = {
    GetVideoById,
    AddProtocol,
    ClearVideoById,
    GetProtocol,
    UpdateProtocol,
    DeleteProtocol,
    AddVideoDescription,
    UpdateTranscription,
    UpdateTranscriptionAll,
    GetChannels,
    CreateVideoUrl,
    MarkVideo,
    ReplaceTranscriptionWord,
    DownloadTranscription,
    GetLink,
    GetPersons
};

export default connect(mapStateToProps, mapDispatchToProps)(VideoDetails);

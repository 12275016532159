// Import packages
import React, {useEffect, useRef, useState} from 'react';

// Import assets
import './protocol-item.scss';
import {
    CloseIcon,
    DeletingIcon,
    EditIcon, EllipsisIcon,
     PinFillIcon, PinOutlineIcon,
    PlusIcon,
    ShareIcon,
} from "../../../../../../image";

//Import utils
import {getTimeString, parseTime, timeWithDots} from "../../../../../../utils/helperFunctions";
import {useTranslation} from "react-i18next";
import InfoTooltip from "../../../Profile/tooltip";
import {MarkDownText} from "../../../../../../shared/features/MarkDownText";
import AutocompleteUsers from "../../../../../../shared/features/autocomplete/AutocompleteUsers";
import UserInfoModal from "../../../Users/modals/userInfoModal/UserInfoModal";
import {Loader} from "../../../../../../shared/features/Loader";
import {updateCheckboxesText, updateDetailsTagMarkdown} from "../../../../../../utils/markdown";
import {useSelector} from "react-redux";
import ProtocolAuthorBlock from "../ProtocolAuthorBlock/ProtocolAuthorBlock";

const initProtocolData = {
    person_id: '',
    author_name: '',
    text: '',
    time: '',
    task_executor_person_id: '',
    task_executor_name: ''
}

function ProtocolItem({
                          addProtocolItem,
                          DeleteProtocol,
                          GetLink,
                          videoId,
                          token,
                          UpdateProtocol,
                          protocol,
                          changeVideoTime,
                          getTime,
                          videoPlayerCurrentTime,
                          AddProtocolHighlight,
                          DeleteProtocolHighlight,
                          prevProtocolId,
                          protocolIndex,
                          authorName,
                          persons,
                          currentItem,
                          MarkVideo,
                          setCopied,
                          GetProtocol,
                          personsList
                      }) {
    const [showNoteMenu, setShowNoteMenu] = useState(false)
    const [isEdit, setIsEdit] = useState(false)
    const [showForm, setShowForm] = useState(false)
    const [protocolData, setProtocolData] = useState(initProtocolData)
    const [error, setError] = useState({
        person_id: false,
        text: false,
        time: false
    })
    const [protocolItem, setProtocolItem] = useState(protocol)
    const [showModal, setShowModal] = useState(false)
    const [userIdModal, setUserIdModal] = useState(false)

    const {t} = useTranslation();
    const ref = useRef();
    const profileInfo = useSelector(state => state.profile?.userProfile)
    const protocolItemPersonId = protocolItem?.person_id;
    const protocolAuthor = (protocolItem?.persons || []).find(item => item.id === protocolItemPersonId)

    const protocolExecutorAuthor = (protocolItem?.persons || []).find(item => item.id === protocolItem?.task_executor_person_id)
    let disabledBtn = !!protocolData.text?.length
    const containerRef = useRef(null);
    const [text, setText] = useState(protocolItem?.text);
    const [openText, setOpenText] = useState(false);

    useEffect(() => {
        currentItem && setTimeout(() => {
            ref.current?.scrollIntoView({behavior: 'smooth', block: 'start'});
        }, 100);
    }, [currentItem]);

    useEffect(() => {
        setProtocolItem(protocol)
    }, [protocol])

    // useEffect(() => {
    //     console.log('authorName',authorName)
    //     setProtocolData({
    //         ...protocolData,
    //         author_name: authorName
    //     })
    //     MarkVideoMarkVideo(protocol)
    // }, [authorName])

    useEffect(() => {
        document.addEventListener('click', closeNoteMenu);
        return () => {
            document.removeEventListener('click', closeNoteMenu)
        }
    }, []);

    const changeNoteData = (e) => {
        const {name, value} = e.target
        if (name === "time" && value && !/^[+\d]\d*$/.test(parseInt(value.replaceAll(':', '')))) {
            return
        }

        let changedData = {
            ...protocolData,
            [name]: value
        }
        if (name === 'author_name') {
            changedData.person_id = ''
        }
        if (name === 'task_executor_name') {
            changedData.task_executor_person_id = ''
        }

        setProtocolData(changedData)

        setError({
            ...error,
            [name]: false
        })
    }

    const changePersonId = (person) => {
        setProtocolData({
            ...protocolData,
            person_id: person.id,
            author_name: `${person.first_name ? person.first_name : ''} ${person.last_name ? person.last_name : ''}`
        })
        setError({
            ...error,
            person_id: false,
        })
    }
    const changeExecutorPersonId = (person) => {
        setProtocolData({
            ...protocolData,
            task_executor_person_id: person.id,
            task_executor_name: `${person.first_name ? person.first_name : ''} ${person.last_name ? person.last_name : ''}`
        })
        setError({
            ...error,
            task_executor_person_id: false,
        })
    }
    const getAuthorNameItem = () =>{
        const {person_id, text, start_time, author_name, task_executor_name, task_executor_person_id} = protocolItem

        if(author_name){
            return author_name
        }else if(protocolAuthor?.first_name){
            return  `${protocolAuthor.first_name ? protocolAuthor.first_name : ''} ${protocolAuthor?.last_name ? protocolAuthor?.last_name : ''}`
        }else if(protocolExecutorAuthor?.id){
            return `id-${protocolExecutorAuthor?.id} ${t('unknown')}`
        }else if(!person_id){
            return `${profileInfo?.first_name} ${profileInfo?.last_name}`
        }
        return ''
    }
    const toggleEdit = (e) => {
        e.stopPropagation()
        e.preventDefault()

        const {person_id, text, start_time, author_name, task_executor_name, task_executor_person_id} = protocolItem
        let timeValue = (start_time || protocol?.start_time !== null) ? getTimeString(start_time) : ''
        setProtocolData({
            ...protocolData,
            person_id: person_id ? person_id : profileInfo?.attached_person?.id || profileInfo?.perosn_id,
            text,
            author_name: getAuthorNameItem(),
            task_executor_person_id,
            task_executor_name: task_executor_name ? task_executor_name
                : protocolExecutorAuthor?.first_name
                    ? `${protocolExecutorAuthor.first_name ? protocolExecutorAuthor.first_name : ''} ${protocolExecutorAuthor?.last_name ? protocolExecutorAuthor?.last_name : ''}`
                    : protocolExecutorAuthor?.id && `id-${protocolExecutorAuthor?.id} ${t('unknown')}`,
            time: timeValue
        })
        setShowNoteMenu(false)
        setShowForm(true)
        setIsEdit(true)
    }


    function toggleUserModal(id) {
        setShowModal(!showModal);
        setUserIdModal(id);
    }

    const closeNoteMenu = () => {
        setShowNoteMenu(false)
    }

    const openProtocolForm = () => {
        setShowForm(true)
        setProtocolData({
            ...protocolData,
            author_name: `${profileInfo?.first_name} ${profileInfo?.last_name}`,
            person_id: profileInfo?.attached_person?.id || undefined,
            time: getTime(videoPlayerCurrentTime) ? getTime(videoPlayerCurrentTime) : 0
        })
    }

    const clearTime = () => {
        setProtocolData({
            ...protocolData,
            time: ''
        })
    }

    const deleteVideoProtocol = () => {
        DeleteProtocol(protocolItem)
        setShowNoteMenu(false)
    }

    const addVideoProtocol = () => {
        const {person_id, text, time, author_name, task_executor_name, task_executor_person_id} = protocolData
        let start_time = parseTime(time)
        let result = true
        let errors = {}

        if (!text) {
            errors.text = true
            result = false
        }

        setError(errors)
        if (result) {
            let reqData = {
                text,
            }

            if (!isEdit) {
                time && (reqData.start_time = start_time)
                videoId && (reqData.video_id = videoId)
                person_id && (reqData.person_id = person_id)
                prevProtocolId && (reqData.insert_after_protocol_block_id = prevProtocolId)
                task_executor_person_id && (reqData.task_executor_person_id = task_executor_person_id)
                if (!person_id && author_name) {
                    reqData.author_name = author_name ? author_name : null
                }
                if (!task_executor_person_id && task_executor_name) {
                    reqData.task_executor_name = task_executor_name
                }

                addProtocolItem(reqData, protocolIndex)
            } else {
                // reqData.start_time = (time && time !== 0) ? start_time : 0
                reqData.start_time = !!start_time ? start_time : null

                if (task_executor_person_id) {
                    reqData.task_executor_person_id = task_executor_person_id
                    reqData.task_executor_name = null
                } else {
                    reqData.task_executor_name = task_executor_name ? task_executor_name : null
                    reqData.task_executor_person_id = null
                }

                if (person_id) {
                    reqData.person_id = person_id
                    reqData.author_name = null
                } else {
                    reqData.author_name = author_name ? author_name : null
                    reqData.person_id = null
                }
                UpdateProtocol(protocolItem.id, reqData)
                MarkVideo(reqData)
                setProtocolItem({
                    ...protocolItem,
                    person_id,
                    author_name,
                    start_time: (time && time !== 0) ? start_time : 0,
                    text
                })
               setText(text)
            }

            setProtocolData(initProtocolData)
            setShowForm(false)
            setIsEdit(false)
        }
    }


    const closeEditing = () => {
        setProtocolData({
            ...initProtocolData,
            author_name: authorName
        })
        setShowForm(false)
        setIsEdit(false)
    }
    const [isLoading, setIsLoading] = useState(false);

    // Функция которая меняет при переключении заголовок кнопки
    useEffect(() => {
        const handleClick = (event) => {
            const detailsElement = event.target.closest('details');
            const summaryElement = event.target.closest('summary');

            const isOpen = detailsElement?.open;
            if(summaryElement){
                if (isOpen) {
                    setOpenText(false);
                    summaryElement.textContent = 'Expand';
                }else{
                    setOpenText(true);
                    summaryElement.textContent = 'Collapse';
                }
            }
        };

        const currentContainer = containerRef.current?.querySelector('details summary');
        currentContainer?.addEventListener('click', handleClick);

        return () => {
            currentContainer?.removeEventListener('click', handleClick);
        };
    }, [containerRef, text]);

    const checkBoxClick = async (value, updateText) => {
        try{
                  await UpdateProtocol(protocolItem.id, {
                      author_name: value?.author_name,
                      person_id: value?.person_id,
                      start_time: value?.start_time,
                      task_executor_name: value?.task_executor_name,
                      task_executor_person_id: value?.task_executor_person_id,
                      text: updateText
                 })
               await GetProtocol(videoId)
        }catch (e){
            console.log(e)
        }
    }
    const openMenuRef = useRef(openText);

    useEffect(() => {
        openMenuRef.current = openText;
    }, [openText]);

    const handleClick = (isChecked, fountText,text) => {
        const getText = updateCheckboxesText(text, fountText, isChecked);
        const updateDetails = updateDetailsTagMarkdown(getText, false, true);
        setText(updateDetailsTagMarkdown(getText, openMenuRef.current));

        checkBoxClick(protocolItem, updateDetails);
    };

    const eventHandlerRef = useRef(null);

    // Обработка клика по чекбоксу в маркдауне
    useEffect(() => {
        const handleChange = (event) => {
            const item = event.target.closest('.markdown-checkbox');
            const id = event.target?.id;
            const isChecked = event.target.checked;
            const foundText = item?.querySelector(`label[for="${id}"]`)?.outerText;
            handleClick(isChecked, foundText, text);
        };

        const container = containerRef.current;

        if (container) {
            const arrElements = container.querySelectorAll('.markdown-checkbox ') || [];
            // Удаление старых обработчиков
            if (eventHandlerRef.current) {
                arrElements.forEach((item) => {
                    item.removeEventListener('change', eventHandlerRef.current);
                });
            }

            // Сохранение ссылки на новый обработчик
            eventHandlerRef.current = handleChange;

            // Добавление нового обработчика
            arrElements.forEach((item) => {
                item.addEventListener('change', eventHandlerRef.current);
            });
        }

        // Очистка обработчиков при размонтировании компонента
        return () => {
            if (container) {
                const arrElements = container.querySelectorAll('.markdown-checkbox') || [];
                arrElements.forEach((item) => {
                    item.removeEventListener('change', eventHandlerRef.current);
                });
            }
        };
    }, [protocolItem, text]);

    return <>
        {
            !isEdit && <div className="protocol-item" ref={ref}>
                {token && <EllipsisIcon className="protocol-item__dots"
                                        onClick={(e) => {
                                            e.stopPropagation()
                                            setShowNoteMenu(!showNoteMenu)
                                        }
                                        }/>}
                {showNoteMenu && <div className="protocol-item__actions">
                    <button className="protocol-item__actions__close"
                            onClick={() => setShowNoteMenu(!showNoteMenu)}>
                        <CloseIcon/>
                    </button>
                    <div className="protocol-item__actions__content">
                        <div className="protocol-item__actions__item" onClick={() => {
                            GetLink({
                                protocol_text_id: protocolItem?.id
                            })
                                .then((res) => {
                                    navigator.clipboard.writeText(res.text);
                                    setCopied(true)
                                })
                        }}>
                            <ShareIcon className="share-icon"/>
                            {t('button_share')}
                        </div>
                        <div className="protocol-item__actions__item"
                             onClick={toggleEdit}>
                            <EditIcon className="edit-icon"/> {t('button_edit')}
                        </div>
                        <div className="protocol-item__actions__item delete"
                             onClick={deleteVideoProtocol}>
                            <DeletingIcon/> {t('button_delete')}
                        </div>
                    </div>
                </div>}

                <div className="protocol-item__content">
                    <div className="left">
                        <div
                            className={`text ${(protocolItem?.isMarked && currentItem) ? 'marked' : ''}`}
                            ref={containerRef}
                        >
                                <MarkDownText
                                  text={text}
                                  id={protocolItem?.id}
                                />
                        </div>
                        <ProtocolAuthorBlock
                          protocolItem={protocolItem}
                          onClick={toggleUserModal}
                        />
                    </div>
                    <div className="right">
                        {protocolItem?.marked ?
                            <InfoTooltip elem={
                                <PinFillIcon className="highlights-star"
                                                onClick={() => {
                                                    if(token){
                                                        DeleteProtocolHighlight({protocol_text_id: protocolItem?.id});
                                                    }
                                                }
                                }/>
                            }
                                         title={t('track_related_people_profile')}
                                         placement={'bottom'}/>
                            : <InfoTooltip elem={
                                <PinOutlineIcon className="highlights-star"
                                                onClick={() => {
                                                    if (token) {
                                                        AddProtocolHighlight({protocol_text_id: protocolItem?.id});
                                                    }
                                                }}
                                />
                            }
                                           title={t('track_related_people_profile')}
                            placement={'bottom'}/>
                        }

                        {
                            (protocolItem?.start_time || protocolItem?.start_time === 0) &&
                            <div className="time"
                                 onClick={() => changeVideoTime(protocolItem?.start_time, protocolItem?.start_time)}
                            >{getTimeString(protocolItem?.start_time || 0)}</div>}
                    </div>
                </div>

                {token && <button className="protocol-item__btn"
                                  onClick={openProtocolForm}>
                    <PlusIcon/>
                </button>}
            </div>
        }
        {
            showForm && <div className="protocol__form">
                <div className="protocol__form__content">
                    <button className="protocol__form__close"
                            onClick={() => {
                                setShowForm(false)
                                setProtocolData(initProtocolData)
                            }}>
                        <CloseIcon/>
                    </button>
                    <div className="protocol__form__title">{isEdit ? t("button_save") : t('button_add')} Примечание</div>
                        <AutocompleteUsers
                          persons={persons}
                          protocolData={protocolData}
                          authorName={protocolData['author_name']}
                          placeholder={t('input_name')}
                          name={'author_name'}
                          onChangeUser={changePersonId}
                          onChangeInput={changeNoteData}
                        />
                    <textarea name="text"
                              placeholder={t('input_message')}
                              value={protocolData.text}
                              className={`${error.text ? 'error' : ''}`}
                              onChange={changeNoteData}
                    />
                    <AutocompleteUsers
                      persons={persons}
                      protocolData={protocolData}
                      placeholder={t('input_to_name')}
                      authorName={protocolData['task_executor_name']}
                      name={'task_executor_name'}
                      onChangeInput={changeNoteData}
                      onChangeUser={changeExecutorPersonId}
                    />
                    <div className="time-wrapper">
                        <input type="text"
                               className={`form_time`}
                               value={protocolData.time ? timeWithDots(protocolData.time) : ''}
                               name={'time'}
                               placeholder={'00:00:00'}
                               maxLength={"8"}
                               onChange={changeNoteData}/>
                        {protocolData?.time && <CloseIcon onClick={clearTime}/>}
                    </div>

                    <div className={`protocol__form__btn ${!disabledBtn ? 'disabled' : ''}`}
                         onClick={disabledBtn && addVideoProtocol}>
                        {isEdit ? t('button_save') : <>{
                            (protocolData.author_name || protocolData.text || protocolData.time)
                                ? t('button_save') : t('button_add')
                        }</>}
                    </div>
                    <div className="protocol__form__btn edit" onClick={closeEditing}>
                        {t('button_cancel')}
                    </div>
                </div>
            </div>
        }

        {showModal && <UserInfoModal show={showModal}
                                     closeModal={toggleUserModal}
                                     user={(personsList?.results || []).find(i => i.id === userIdModal)}
        />}
    </>
}

export default ProtocolItem;

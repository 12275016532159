// Import packages
import React, {useState, useEffect} from 'react';
import {connect, useDispatch} from "react-redux";

// Import assets
import './manageVideo.scss';

// Import Components
import ListItem from "./ListItem/ListItem";
import CustomPagination from "../../../shared/features/Pagination/CustomPagination";

// Import Utils
import {getPropsFromState} from "../../../redux/mapStateToProps";
import {
	AddVideoDescription,
	GetVideos,
	UpdateVideoChannel,
	ChangeHeaderTitle, ReprocessingVideoService
} from "../../../redux/actions";
import {useTranslation} from "react-i18next";
import InfoTooltip from "../../Dashboard/containers/Profile/tooltip";
import {artifacts_preview, ErrorIcon, SuccessIcon} from "../../../image";
import {Toast} from "react-bootstrap";
import {LayoutContainer} from "../../../modules/Layout";


function ManageVideo(props) {
	const {videosList, channels, isFullWidth} = props;
	const {t} = useTranslation();
	const [toastInfo, setToastInfo] = useState({isShow: false, type: 'danger'});
	const dispatch = useDispatch();
	const [activePage, setActivePage] = useState(1);
	const [isLoading, setIsLoading] = useState({id:null, show:false});

	useEffect(() => {
		props.GetVideos({limit: 20, manage: true})
		props.ChangeHeaderTitle(t('manage_video_title'))
	}, []);

	const changePage = (number) => {
		setActivePage(number)
		props.GetVideos({limit: 20, offset: (number - 1) * 20, manage: true})
	};

	let channelsList = {}

	const colors = {
		'1': {
			color: '#4F40F7',
			bgColor: '#4F40F719',
		},
		'2': {
			color: '#2877D5',
			bgColor: '#2877D419',
		},
		'3': {
			color: '#F7404B',
			bgColor: '#F7404B19',
		},
	}

	channels?.length && channels.forEach(item => {
		channelsList = {
			...channelsList,
			[item.id]: {
				name: item.name,
				color: colors[item.id]?.color,
				bgColor: colors[item.id]?.bgColor,
			}
		}
	})

	const tableInfo = [
		{
			name: t('table_name'),
			width: '12.8%'
		},
		{
			name: t('table_data'),
			width: '7.7%'
		},
		{
			name: <InfoTooltip elem={t('table_duration_short')}
			                   title={t('table_duration')}/>,
			fullName: t('table_duration'),
			width: '6.2%'
		},
		{
			name: t('table_status'),
			width: '6.2%'
		},
		{
			name: t('table_owner'),
			width: '12.8%'
		},
		{
			name: t('table_link'),
			width: '10.9%'
		},
		{
			name: t('table_channels'),
			width: '15%'
		},
		{
			name: t('table_participant'),
			width: '8.1%'
		},
	]


	const reprocessingVideo = async (id, lng) => {
		try {
      setIsLoading({id, show:true});
			await dispatch(ReprocessingVideoService(id, {language: lng}));
			await dispatch(GetVideos({limit: 20, offset: (activePage - 1) * 20, manage: true}));
			setToastInfo({
				isShow: true,
				type: 'success',
				text: ''
			});
      setIsLoading({id:null, show:false});
		} catch (e) {
			setToastInfo({
				isShow: true,
				type: 'danger',
				text: ''
			});
        setIsLoading({id:null, show:false});
        console.error(e)
		}
	}

	return (
		<LayoutContainer>
		<div className={`main-container ${isFullWidth ? '' : 'full'} manage-video-wrapper`}>
			<div className="manage-video">
				<div className="page-title" style={{padding: '10px 0'}}>{t('menu_item_artifacts')}</div>
				{!videosList?.results?.length ?
					<div className="artifacts__wrap">
						<div className="artifacts__wrap__container">
							<div className="preview">
								<div className="preview__descriptions">
										<span
											className="preview__descriptions--text">{t('artifacts_preview_text_1')}</span>
									<span
										className="preview__descriptions--text">{t('artifacts_preview_text_2')}</span>
									<ul className="preview__descriptions--list">
										<li><span
											className="preview__descriptions--text">{t('artifacts_preview_text_3')}</span>
										</li>
										<li><span
											className="preview__descriptions--text">{t('artifacts_preview_text_4')}</span>
										</li>
										<li><span
											className="preview__descriptions--text">{t('artifacts_preview_text_5')}</span>
										</li>
										<li><span
											className="preview__descriptions--text">{t('artifacts_preview_text_6')}</span>
										</li>
									</ul>
								</div>
								<img
									className="preview--img"
									src={artifacts_preview} alt=""/>
							</div>
						</div>
					</div>
					:
					<>
						<div className="table-manage-video">

							<div className={'table-header'}>
								{
									tableInfo?.map(item => {
										return <div key={item?.name}
													style={{width: item?.width}}
										>{item?.name}</div>
									})
								}
								<th style={{width: '2.2%'}}></th>
							</div>
							{videosList?.results?.map((item) => {
								return <ListItem data={item}
												 key={item.id}
												 channelsList={channelsList}
												 tableInfo={tableInfo}
												 activePage={activePage}
												 reprocessingVideo={reprocessingVideo}
												 isLoading={isLoading}
								/>
							})}
						</div>
						{videosList?.count > 20 && <CustomPagination count={videosList?.count}
																	 activePage={activePage}
																	 listLength={20}
																	 changePage={changePage}/>}
					</>
				}
			</div>
			<Toast
				className='upload_video_answer'
				onClose={() => setToastInfo({isShow: false, type: toastInfo.type})}
				show={toastInfo.isShow}
				delay={3000}
				autohide
			>
				<div className="toasblock">
					{toastInfo.type === 'danger' ? <ErrorIcon/> : <SuccessIcon/>}
					<div className="toasblock__wrap">
						<span
							className="toasblock__wrap--title">{toastInfo.type === 'danger' ? t('toast_error_title') : t('toast_success_title')}</span>
						{toastInfo?.text ? <span className="toasblock__wrap--text">{toastInfo?.text || ''}</span> : null}
					</div>
				</div>
			</Toast>
		</div>
		</LayoutContainer>
	);
}

const mapStateToProps = (state) => {
	return getPropsFromState(state, [
		'videosList',
		'channels',
		'isFullWidth'
	])
};

const mapDispatchToProps = {
	GetVideos,
	UpdateVideoChannel, AddVideoDescription, ChangeHeaderTitle
};

export default connect(mapStateToProps, mapDispatchToProps)(ManageVideo);

// Import packages
import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import {Form, Modal} from "react-bootstrap";

// Import assets
import './addRole.scss';
import {CloseIcon, DownIcon} from "../../../../../../image";

// Import Utils
import {getPropsFromState} from "../../../../../../redux/mapStateToProps";
import {ClearRoleById, CreateRole, GetAuthCheck, GetReisRoles, UpdateRole} from "../../../../../../redux/actions";
import {useTranslation} from "react-i18next";
import {
    rolePermissions,
    rolePermissionsKeys,
    rolePermissionTypes
} from "../../../../../../utils/constants";
import DropdownMenu from "../../../../../../shared/features/dropdownMenu";
import config from "../../../../../../utils/config";

const initialState = {
    name: undefined,
    description: undefined,
    reis_role_ids: [],
    permissions: [],
}
const initialErrors = {
    name: false,
    description: false,
    permissions: false,
}

function AddRole(props) {
    const {show, setEditedRole, editedRole, GetRoleById, ClearRoleById, roleById, reisList} = props;

    const {t, i18n} = useTranslation();

    const [roleInfo, setRoleInfo] = useState(initialState);
    const [errors, setErrors] = useState(initialErrors);
    const [haveChanges, setHaveChanges] = useState(new Map());
    const [userPermissions, setUserPermissions] = useState([]);


    let disabledBtn = !!Object.values(roleInfo).find(item => !!item)?.length

    useEffect(() => {
        if (editedRole?.id) {
            GetRoleById(editedRole?.id)
        }
    }, [editedRole])

    useEffect(() => {
        if (roleById?.id) {
            let list = []
            list = [...roleById?.permissions.filter(i => i !== 'users_check_email')]
            !!roleById?.permissions?.length
            && Object.keys(rolePermissionsKeys).forEach(item => {
                let keyLength = !!roleById?.permissions?.length && roleById?.permissions.filter(i => i.includes((item)) && i !== 'users_check_email')?.length
                if (keyLength > 1 && rolePermissionsKeys[item].length !== keyLength) {
                    list = [...list, `${item}_all`]
                }
            })
            setUserPermissions(list)

            setRoleInfo({
                ...roleInfo,
                name: roleById?.name,
                description: roleById?.description,
                permissions: list,
                reis_role_ids: roleById?.reis_roles?.map(item => item?.id)
            })
        }
        props.GetReisRoles()
    }, [roleById])

    function clearHaveChanges() {
        const changes = new Map(haveChanges);
        changes.clear()
        setHaveChanges(changes)
    }

    function getInputValue(e) {
        const {name, value} = e.target
        setRoleInfo({
            ...roleInfo,
            [name]: value
        })

        const changes = new Map(haveChanges);
        if (roleById?.[name] !== value) {
            changes.set(name, value)
        } else {
            changes.delete(name)
        }
        setHaveChanges(changes)
    }

    function toggleCheck(permission) {
        let list = roleInfo.permissions

        if (list?.includes(permission)) {
            list = list.filter(i => i !== permission)
        } else {
            list = [...list, permission]
        }
        const permissionArr = permission.split('_')
        permissionArr.pop()
        const key = permissionArr.join('_')
        if (list.includes(`${key}_all`)) {
            if (list?.filter(i => i.includes(key))?.length === 1) {
                list = [...list.filter(i => !i.includes(`${key}_all`))]
            }
        } else {
            rolePermissionsKeys[key]?.length > 1 && (list = [...list, `${key}_all`])
        }

        setRoleInfo({
            ...roleInfo,
            permissions: list
        })

        const changes = new Map(haveChanges);

        if (userPermissions?.sort()?.toString() !== list?.sort()?.toString()) {
            changes.set('permissions', list)
        } else {
            changes.delete('permissions')
        }
        setHaveChanges(changes)
    }

    function toggleCheckAll(permissions) {
        let list = roleInfo.permissions

        if (!permissions.every(element => list.includes(element))) {
            list = [...list, ...permissions]
        } else {
            list = [...list.filter(i => !permissions.includes(i))]
        }
        setRoleInfo({
            ...roleInfo,
            permissions: list
        })

        const changes = new Map(haveChanges);
        if (userPermissions?.sort()?.toString() !== list?.sort()?.toString()) {
            changes.set('permissions', list)
        } else {
            changes.delete('permissions')
        }
        setHaveChanges(changes)
    }

    function validateData(data) {
        const {name} = data
        setErrors(initialErrors)
        let isValid = true;
        let errorList = {...initialErrors}

        if (!name?.length || name?.length < 2) {
            isValid = false
            errorList = {
                ...errorList,
                name: t('error_name')
            }
        }

        setErrors(errorList)

        return isValid
    }

    function saveChanges() {
        const recData = {
            name: roleInfo.name,
        };
        let checkedList = []

        checkedList = [...checkedList.filter(i => i.includes('all')), ...roleInfo.permissions]
        roleInfo?.description && (recData.description = roleInfo.description)
        !!roleInfo?.reis_role_ids?.length && (recData.reis_role_ids = roleInfo.reis_role_ids)
        !!checkedList?.length && (recData.permissions = checkedList)

        if (validateData(recData)) {
            roleById?.id
                ? props.UpdateRole(roleById?.id, recData)
                : props.CreateRole(recData)
            props.GetAuthCheck()
            onClose()
        }
    }

    function onClose() {
        setRoleInfo(initialState)
        setErrors(initialErrors)
        setEditedRole(undefined)
        ClearRoleById()
        clearHaveChanges()
        props.closeModal()
    }

    function checkReis(reis) {
        const list = !roleInfo?.reis_role_ids?.includes(reis?.id)
            ? [...roleInfo?.reis_role_ids, reis?.id]
            : roleInfo?.reis_role_ids.filter(i => i !== reis?.id)

        setRoleInfo({
            ...roleInfo,
            reis_role_ids: list
        })

        const changes = new Map(haveChanges);

        const reis_roles = roleById?.reis_roles?.map(i => i.id)
        if (reis_roles?.sort()?.toString() !== list?.sort()?.toString()) {
            changes.set('reis_role_ids', list)
        } else {
            changes.delete('reis_role_ids')
        }
        setHaveChanges(changes)
    }
    const isMax = config.productName.includes('max')
    let rolesNames = []

    !!reisList?.length && reisList.forEach(item => {
        if (roleInfo.reis_role_ids?.includes(item.id)) {
            rolesNames.push(item?.name)
        }
    })

    return <Modal
        show={show}
        onHide={onClose}
        size="xl"
        centered
    >
        <div className="role_modal">
            <div className="role_modal__title">
                {t('add_role')}
                <CloseIcon onClick={onClose}/>
            </div>

            <div className="input_group">
                <label htmlFor="">{t('roles_input_name')}</label>
                <input type="text"
                       value={roleInfo?.name}
                       autoComplete={'off'}
                       name={'name'}
                       onChange={getInputValue}
                       placeholder={t('roles_input_name')}
                />
                {!!errors.name && (
                    <div className="input_group__error">{errors.name}</div>
                )}
            </div>

            {isMax && <div className="select_group">
                <label htmlFor="">{t('roles_input_description_label')}</label>
                <DropdownMenu button={
                    <button className="reis-btn">
                        {!rolesNames.length ? t('roles_input_description')
                            : <span className="reis-btn__name">
                                {rolesNames?.[0]}
                                {rolesNames?.length > 1 &&
                                    <span className="reis-count">+{rolesNames?.length - 1}</span>}
                        </span>}

                        <DownIcon className="speakers-arrow"/>
                    </button>
                }
                              closeAfterToggle={false}
                              className={'reis-select'}
                              contentWidth={'100%'}>
                    <Form>
                        {
                            !!reisList?.length && reisList?.map((item) => {
                                return <div className="reis-select__item"
                                            key={item?.id}>
                                    <Form.Check
                                        className="reis-select__item__check"
                                        type={'checkbox'}
                                        checked={roleInfo?.reis_role_ids?.includes(item?.id)}
                                        onChange={() => {
                                            checkReis(item)
                                        }}
                                    />
                                    <div className="reis-select__item__name">
                                        {item?.name}
                                    </div>
                                </div>
                            })
                        }
                    </Form>

                </DropdownMenu>

                {rolesNames?.length > 1 && <div className="select_group__list">
                    {
                        rolesNames?.map(item => {
                            return <div className="reis-item" key={item}>
                                {item}
                            </div>
                        })
                    }
                </div>}

                {/*{!!errors.description && (*/}
                {/*    <div className="input_group__error">{errors.description}</div>*/}
                {/*)}*/}

            </div>}

            <div className="role_modal__permissions">
                {
                    Object.entries(rolePermissions(i18n.language)).map(([key, value]) => {
                        return <div className="permission_item" key={key}>
                            <div className="permission_item__title">{key}</div>

                            <Form>
                                {
                                    value?.map(item => {
                                        let label = item.split('_')
                                        label = rolePermissionTypes(i18n.language)[label[label?.length - 1]]

                                        return item.includes('all') ? <Form.Check
                                            name="type"
                                            key={item}
                                            label={label || item}
                                            type={'checkbox'}
                                            className={`${value?.every(element => roleInfo?.permissions?.includes(element)) ? '' : 'check_part'}`}
                                            checked={!!roleInfo.permissions.includes(item)}
                                            onChange={() => toggleCheckAll(value)}
                                        /> : <Form.Check
                                            name="type"
                                            key={item}
                                            label={label || item}
                                            type={'checkbox'}
                                            checked={!!roleInfo.permissions.includes(item)}
                                            onChange={() => toggleCheck(item)}
                                        />
                                    })
                                }
                            </Form>
                        </div>
                    })
                }

            </div>

            <div className="role_modal__buttons">
                <button className={`close_btn`}
                        onClick={onClose}
                >
                    {t('button_cancel')}
                </button>

                {
                    !!roleById?.id ? <button className={`save_btn ${!haveChanges.size ? 'disabled' : ''}`}
                                             disabled={!haveChanges.size}
                                             onClick={saveChanges}
                        >
                            {t('button_save')}
                        </button>
                        : <button className={`save_btn ${!disabledBtn ? 'disabled' : ''}`}
                                  disabled={!disabledBtn}
                                  onClick={saveChanges}
                        >
                            {t('button_save')}
                        </button>
                }

            </div>
        </div>
    </Modal>
}

const mapStateToProps = (state) => {
    return getPropsFromState(state, ['reisList'])
};

const mapDispatchToProps = {
    CreateRole,
    ClearRoleById,
    UpdateRole,
    GetAuthCheck,
    GetReisRoles,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddRole);

import React from 'react';
import './ProfileRecordingBlock.scss';
import {ArrowDownIcon} from "../../../../image";
import {Accordion} from "react-bootstrap";
import UserSignatures from "../../../../shared/profile/UserSignatures/UserSignatures";
import {useTranslation} from "react-i18next";

const ProfileRecordingBlock = () => {
	const {t} = useTranslation();

	return (
		<div className="recognition">
			<div className="recognition__wrap">
				<span className="recognition__wrap--title">{t('recognition_settings')}</span>
				<span className="recognition__wrap--description">{t('recognition_settings_description')}</span>
				<Accordion defaultActiveKey="1" className="recognition__accordion accordion-wrapper">
					<Accordion.Item eventKey="0"  className="recognition__accordion--item">
						<Accordion.Header
							className="recognition__accordion--header">
							{t('name_in_video_conference')}
							<ArrowDownIcon className={`arrow-icon`}/>
						</Accordion.Header>
						<Accordion.Body>
							<UserSignatures/>
						</Accordion.Body>
					</Accordion.Item>
				</Accordion>
			</div>
		</div>
	);
};

export default ProfileRecordingBlock;
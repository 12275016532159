// Import packages
import React, {Fragment, useEffect, useState} from 'react';
import {connect, useDispatch, useSelector} from "react-redux";
import {Link, useLocation, useParams} from "react-router-dom";

// Import assets
import './user-videos-page.scss';

// Import Components
import VideoCard from "../Channels/card/VideoCard";
import {Breadcrumb, Toast} from "react-bootstrap";

// Import Utils
import {getPropsFromState} from "../../../../redux/mapStateToProps";
import {
    ChangeHeaderTitle,
    GetVideos,
    GetVideosSearch,
    GetPersons,
    GetServiceStatuses,
    UserDetachVideoService, GetVideosService
} from "../../../../redux/actions";
import {GetChannels} from "../../../../redux/actions";
import {useTranslation} from "react-i18next";
import VideoBlock from "../Channels/VideoBlock/VideoBlock";
import UserInfoModal from "../Users/modals/userInfoModal/UserInfoModal";
import {findFirstIntersectionChannel} from "../../../../utils/helperFunctions";
import {Loader} from "../../../../shared/features/Loader";
import {CloseIcon, ErrorIcon, SuccessIcon} from "../../../../image";
import {Tooltip} from "antd";

function UserVideosPage(props) {
    const location = useLocation();
    const {t} = useTranslation();
    const channels = useSelector(state => state.channels?.channels);
    const [isLoading, setIsLoading] = useState({isShow: false, id: 0});
    const [toastInfo, setToastInfo] = useState({isShow: false, type: 'danger'});
    const dispatch = useDispatch();
    const user = useSelector(state => state.profile?.userProfile)

    function isCheckUserKnow(data) {
        return !!(data?.image_path || data?.avatar) && (data?.image_path || data?.avatar) !== "None"
    }

    useEffect(() => {
        window.scrollTo({
            top: 0
        })
        dispatch(GetVideosService({
            person_id: location?.state?.personId,
            limit: 1000
        }));
        const title = t('menu_item_review')
        props.ChangeHeaderTitle(title)
    }, [location?.state?.personId]);

    let channelsList = {}

    const colors = {
        '1': {
            color: '#4F40F7',
            bgColor: '#4F40F719',
        },
        '2': {
            color: '#2877D5',
            bgColor: '#2877D419',
        },
        '3': {
            color: '#F7404B',
            bgColor: '#F7404B19',
        },
    }

    props?.channels?.length && props.channels.forEach(item => {
        channelsList = {
            ...channelsList,
            [item.id]: {
                name: item.name,
                color: colors[item.id]?.color,
                bgColor: colors[item.id]?.bgColor,
            }
        }
    })


    const onDetachUser = async (videoId) => {
        try {
            setIsLoading({isShow: true, id: videoId});
            await dispatch(UserDetachVideoService({
                video_id: Number(videoId),
                person_id: location?.state?.personId || null,
            }));
            await dispatch(GetVideos({
                limit: 1000,
                filters: location?.state?.personId ? [
                    {
                        firstColumnVal: 'and',
                        secondColumnVal: 'staff',
                        thirdColumnVal: 'present',
                        fourthColumnVal: location?.state?.personId
                    }
                ] : []
            }));
            setToastInfo({
                isShow: true,
                type: 'success',
                text: t('unpin_user_video_success')
            });
            setIsLoading({isShow: false, id: 0});
        } catch (error) {
            console.error(error);
            setToastInfo({
                isShow: true,
                type: 'danger',
                text: t('unpin_user_video_error')
            });
            setIsLoading({isShow: false, id: 0});
        }
    }

    return !!props?.videosList?.results?.length && <div className={`main-container user-video-container ${props.isFullWidth ? '' : 'full'}`}>
        <div className="user-videos-page">

            <Breadcrumb>
                {
                    !!location?.state?.routs?.length && location?.state?.routs?.map(item => {
                        return   <Breadcrumb.Item key={item?.name}
                                                  active={item?.active}
                                                  className={item?.active ? 'breadcrumb_name' : ''}>
                            <Link to={item?.path}>{item?.name}</Link>
                        </Breadcrumb.Item>
                    })
                }
            </Breadcrumb>

            <div
                className={`user-videos-page__videos`}
            >
                {!!props?.videosList?.results?.length ? <>
                        {
                            props?.videosList?.results?.map((item) => {
                                const channelInfo = findFirstIntersectionChannel(item?.channels || [], channels)
                                return (
                                    <div className="user-videos-page__videos--item">
                                        <VideoBlock
                                            video={item}
                                            badge={channelInfo}
                                            channelsList={channelsList}
                                            // chainList={chainsList}
                                            isDetach={true}
                                            isPreview={true}
                                            // isChaneType={true}
                                            routs={location?.state?.routs}
                                            isNewPersons={true}
                                        />
                                        <Tooltip
                                            placement="right"
                                            title={t('unpin_user_video')}
                                            className={"user-videos-page__videos--detach"}
                                        >
                                            <Fragment>

                                                    <div className="user-videos-page__videos--detach--icon">
                                                        {(isLoading.isShow && isLoading.id === (item?.id)) ?
                                                            <Loader size={20}/>
                                                            :
                                                            <CloseIcon
                                                                onClick={(e) => {
                                                                    if(!isLoading.isShow) {
                                                                        onDetachUser(item?.id)
                                                                    }
                                                                }}
                                                            />
                                                        }
                                                    </div>
                                            </Fragment>
                                        </Tooltip>
                                    </div>
                                )
                            })
                        }
                    </>
                    : <div className={`channels__section-videos-empty`}>
                        {t('empty_list')}
                    </div>}
            </div>
        </div>

        <Toast
            className='upload_video_answer'
            onClose={() => setToastInfo({isShow: false, type: toastInfo.type})}
            show={toastInfo.isShow}
            delay={3000}
            autohide
        >
            <div className="toasblock">
                {toastInfo.type === 'danger' ? <ErrorIcon/> : <SuccessIcon/>}
                <div className="toasblock__wrap">
						<span
                            className="toasblock__wrap--title">{toastInfo.type === 'danger' ? t('toast_error_title') : t('toast_success_title')}</span>
                    <span className="toasblock__wrap--text">{toastInfo?.text || ''}</span>
                </div>
            </div>
        </Toast>
    </div>
}

const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        'isMobile',
        'channels',
        'videosList',
        'isFullWidth',
        'userRoles'
    ])
};

const mapDispatchToProps = {
    GetVideos,
    GetVideosSearch,
    GetChannels,
    ChangeHeaderTitle,
    GetPersons,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserVideosPage);

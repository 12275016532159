export const isCheckUserInfo = (userInfo, personById, userById) => {
	console.log('GGG',personById)
	console.log('RRR',userById)
	return {
		...userInfo,
		first_name: personById?.first_name || userById?.first_name,
		last_name: personById?.last_name || userById?.last_name,
		organization: personById?.organization || (userById?.company?.name || undefined),
		position: personById?.position || undefined,
		note: personById?.note || undefined,
		associated_user: personById?.associated_user || undefined,
		login: personById?.associated_user?.login || userById?.login,
		user_id: personById?.associated_user?.id || userById?.id || undefined,
		person_id: personById?.id || undefined
	}
}

export const isCheckContactInfo = (contactItems, personById) => {
	return {
		...contactItems,
		email: personById?.email?.length ? personById?.email : [''],
		phone_number: personById?.phone_number?.length ? personById?.phone_number : [''],
		linkedin: personById?.linkedin?.length ? personById?.linkedin : [''],
		facebook: personById?.facebook?.length ? personById?.facebook : [''],
	}
}
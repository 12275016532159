import React, {useCallback, useContext, useEffect, useState} from 'react';
import './UserSignatures.scss'
import {
	DeleteUserSignaturesService,
	GetUserSignaturesService,
	SaveSignatureService,
} from "../../../redux/actions/signaturesActions";
import {Toast} from "react-bootstrap";
import {CloseStrokeIcon, ErrorIcon, PlusIcon, SuccessIcon} from "../../../image";
import {connect, useDispatch, useSelector} from "react-redux";
import {getPropsFromState} from "../../../redux/mapStateToProps";
import {GetPersonById} from "../../../redux/actions";
import {useTranslation} from "react-i18next";
import {UserPersonInfoContext} from "../../../modules/UserInfo/components/contexts";

function validateString(str) {
	const words = str.trim().split(/\s+/);
	return !!(words.length >= 2 && words.every(word => word.length >= 3));
}

// onAddSignature - функция которая вызывается, при сохранении сигнатуры
const UserSignatures = (props) => {
	const {onAddSignature} = props;
	const [error, setErrorText] = useState();
	const [toastInfo, setToastInfo] = useState({isShow: false, type: 'danger'});
	const [checkSignature, setCheckSignature] = useState();
	const [isVisibleForm, setVisibleForm] = useState(false);

	const toggleForm = useCallback(() => setVisibleForm(prevState => !prevState), []);
	const {t} = useTranslation();
	const [signatures, setSignatures] = useState([]);
	const dispatch = useDispatch();
	const {userInfo,updateUserInfo,personById} = useContext(UserPersonInfoContext);
	const personInfo = useSelector(state => state.persons.personById);

	const onChangeSignature = async (e) => {
		const value = e.target?.value;
		setCheckSignature(value);

		if(Boolean(error)) setErrorText('');
	}

	const onSaveSignatures = async () => {
		try {
			if(!validateString(checkSignature)) {
				setErrorText(t('validate_signature'))
			} else {
				if(Boolean(error)) setErrorText('');
				if(userInfo?.person_id) {
					 await SaveSignatureService({
						signature_str: (checkSignature || '').trim(),
						person_id: (userInfo?.person_id || null)
					});
					await updateUserInfo({user_id: userInfo?.user_id, person_id: userInfo?.person_id});
					setToastInfo({
						isShow: true,
						type: 'success',
						text: t("add_signature_success")
					});
				}else {
					if(onAddSignature) onAddSignature([...signatures,checkSignature]);
					setSignatures([...signatures,checkSignature]);
				}
				setCheckSignature('');
			}
		} catch (error) {
			if(error?.response?.data && Number(error?.response?.data)) {
				const person  = await dispatch(GetPersonById(Number(error?.response?.data, true)));
				setErrorText(`${t('add_signature_error_v1')} ${person?.first_name || ''} ${person?.last_name || ''}`)
			} else {
				setToastInfo({
					isShow: true,
					type: 'danger',
					text: t('add_signature_error_v2')
				});
			}
		}
	}
	const deleteSignature = async (data) => {
		try {
			if(Boolean(error)) setErrorText('');
			if(userInfo?.person_id){
				await props.DeleteUserSignaturesService({
					signature_str: data || null,
				});
				await updateUserInfo({user_id: userInfo?.user_id, person_id: userInfo?.person_id});

			}else {
				const arr = signatures.filter((item)=>item !== data);
				setSignatures(arr);
				if(onAddSignature) onAddSignature(arr)
			}

			setToastInfo({
				isShow: true,
				type: 'success',
				text: t('delete_signature_success')
			});
		} catch (e) {
			setToastInfo({
				isShow: true,
				type: 'danger',
				text: t('add_signature_error_delete')
			});
		}
	}

	return (
		<div className="signature">
			{!isVisibleForm ?
				<div className="signature__add-btn" onClick={toggleForm}>
					<PlusIcon/>
					<button className="button button--success"> {t('add_signature')}</button>
				</div>
				: null
			}
			{isVisibleForm ?
				<div className="signature__form">
					<div
						className="signature__container"
					>
						<input
							type="text"
							className="signature__container--input"
							value={checkSignature || ''}
							onChange={onChangeSignature}
						/>
					</div>
					<button
						className="button"
						onClick={() => {
							toggleForm();
							setCheckSignature('');
							setErrorText('')
						}}
					>
						{t('button_cancel_v2')}
					</button>
					<button
						className="button button--success"
						onClick={onSaveSignatures}
						disabled={!Boolean(checkSignature)}
					>
						{t('button_save')}
					</button>
				</div>
				: null}
			{Boolean(error) &&
				<div className="error__block">
					<ErrorIcon/>
					<p className="error__block--text">{error}</p>
				</div>}
			{(userInfo?.person_id ? (personInfo?.signatures || [])  : signatures).length ?
				<div className="signature__list">
					{(userInfo?.person_id ? personInfo?.signatures : signatures).map((item, index) => (
						<div
							key={index}
							className="signature__list--item"
						>
							{userInfo?.person_id ? item : item}
							<CloseStrokeIcon
								onClick={() => deleteSignature(item)}
							/>
						</div>
					))}
				</div>
				: null
			}
			<Toast
				className='upload_video_answer'
				onClose={() => setToastInfo({isShow: false, type: toastInfo.type})}
				show={toastInfo.isShow}
				delay={3000}
				autohide
			>
				<div className="toasblock">
					{toastInfo.type === 'danger' ? <ErrorIcon/> : <SuccessIcon/>}
					<div className="toasblock__wrap">
						<span
							className="toasblock__wrap--title">{toastInfo.type === 'danger' ? t('toast_error_title') : t('toast_success_title')}</span>
						<span className="toasblock__wrap--text">{toastInfo?.text || ''}</span>
					</div>
				</div>
			</Toast>
		</div>
	);
};

const mapStateToProps = (state) => {
	return getPropsFromState(state, ['userSignatures', 'userProfile', 'personsList'])
};

const mapDispatchToProps = {
	GetUserSignaturesService,
	DeleteUserSignaturesService,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserSignatures);
// Import packages
import React, {useContext, useState} from 'react';
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";

// Import assets
import './participation-data.scss';

// Import Components
import CombinePersonsDropdown from "../../../../../shared/features/PombinePersons/CombinePersonsDropdown";

// Import Utils
import {getAuthorName, getTimeString} from "../../../../../utils/helperFunctions";
import {
    CloseIcon, DeletingIcon,
    ErrorIcon,
    PictureIcon,
    PinFillIcon,
    PlayIcon,
    SuccessIcon
} from "../../../../../image";
import {useDispatch, useSelector} from "react-redux";
import {
    ClearPersonById, ClearProfile,
    DeleteHighlights, DeletePersonService,
    GetVideosService, LogOut,
    UserDetachVideoService
} from "../../../../../redux/actions";
import ProtocolAuthorBlock from "../../VideoDetails/protocol/ProtocolAuthorBlock/ProtocolAuthorBlock";
import VideoBlock from "../../Channels/VideoBlock/VideoBlock";
import {generateChannelsList} from "../../../../../shared/features/Chains/helpers";
import {Loader} from "../../../../../shared/features/Loader";
import {Toast} from "react-bootstrap";
import {Skeleton, Tooltip} from "antd";
import {history} from "../../../../../utils/history";
import {ProfileRecordingBlock} from "../../../../../modules/UserInfo/components/ProfileRecordingBlock";
import {UserPersonInfoContext} from "../../../../../modules/UserInfo/components/contexts";
import dayjs from "dayjs";
import {monthsShort, monthsShortEn} from "../../../../../utils/constants";

function ParticipationData(props) {
    const {videosList, user, highlights, avatar, setVideoList, isLoadingVideos, closeModal} = props;
    const {t, i18n} = useTranslation();
    const dispatch = useDispatch();
    const channels = useSelector(state => state.channels?.channels || []);
    const channelsList = generateChannelsList(channels)
    const [isLoading, setIsLoading] = useState({isShow: false, id: 0});
    const url = window.location.pathname;
    const parts = url.split('/');
    const userIdQuery = parts.at(-1);
    const [toastInfo, setToastInfo] = useState({isShow: false, type: 'danger'});
    const {getModalInfo, userInfo, updateUserInfo} = useContext(UserPersonInfoContext);
    const personId = userInfo?.person_id  ? userInfo?.person_id : (Number(userIdQuery) || null);
    const userId = userInfo?.user_id ? userInfo?.user_id : (Number(userIdQuery) || null);

    const formatDate = (date, lng) => {
        const day = dayjs(date).date();
        const monthIndex = dayjs(date).month(); // 0-11
        const year = dayjs(date).year();
        const month = lng === 'ru' ? monthsShort[monthIndex] : monthsShortEn[monthIndex];

        return `${day} ${month} ${year}`;
    };

    function findFirstIntersection(array1, array2) {
        for (let item1 of array1) {
            const matchingItem = array2.find(item2 => item1 === item2.id);
            if (matchingItem) {
                return matchingItem
            }
        }
        return null;
    }

    const logOut = async () => {
        await dispatch(LogOut())
        history.push('/');
        ClearProfile()
    }

    function navigateTo() {
        history.push({
            pathname: `/dashboard/videos/userVideos/${personId}`,
            state: {
                personId: personId,
                routs: [
                    {
                        path: '/dashboard/profile',
                        name: t('profile_profile')
                    },
                    {
                        path: `/dashboard/videos/userVideos/${personId}`,
                        name: getAuthorName(user?.id ? user : user, null, i18n.language),
                        active: true
                    }
                ]
            }
        })
    }

    const onDetachUser = async (video) => {
        try {
            setIsLoading({isShow: true, id: video?.id});
            await dispatch(UserDetachVideoService({
                video_id: Number(video?.id),
                person_id: personId
            }));
            const videosResponse = await dispatch(GetVideosService({
                limit: 3,
                person_id: personId
            }))
            setToastInfo({
                isShow: true,
                type: 'success',
                text: t('unpin_user_video_success')
            });
            setIsLoading({isShow: false, id: 0});
            setVideoList && setVideoList(videosResponse || {});
        } catch (error) {
            console.error(error);
            setToastInfo({
                isShow: true,
                type: 'danger',
                text: t('unpin_user_video_error')
            });
            setIsLoading({isShow: false, id: 0});
        }
    }

    async function deletePerson() {
        try {
            await DeletePersonService(personId);
            await dispatch(ClearPersonById());
            setToastInfo({
                isShow: true,
                type: 'success',
                text: t("delete_participant_success_text")
            });
            if(personId){
                if(updateUserInfo) await updateUserInfo({person_id: null, user_id: userId})
                if(getModalInfo) await getModalInfo({person_id: null, user_id: userId})
            }
            if(!userId){
               if(closeModal) {
                   closeModal()
               }else{
                   logOut();
               }
            }

        } catch (error) {
            console.error(error);
            setToastInfo({
                isShow: true,
                type: 'danger',
                text: t("delete_participant_error_text")
            });
        }
    }

    return <div className="participation-data">
        {isLoadingVideos ?
            <div className="mock-wrap">
                {Array(3).fill(null).map((item) => {
                    return (
                        <div className="mock-wrap--item">
                            <Skeleton.Node active={true} block={true}/>
                        </div>
                    )
                })}
            </div>
            :
            <>

                {
                    videosList?.count ? <div>
                            <div className={'participation-data__wrap'}>
                                <span className={'participation-data__wrap--title'}>{t('profile_video')}</span>
                                {videosList?.count > 3 ?
                                    <span
                                        className="count"
                                        onClick={navigateTo}
                                    >
		              {t('see_all')}&nbsp;({(videosList?.count)})
								</span>
                                    : null
                                }
                            </div>

                            <div className="video_list">
                                {
                                    videosList?.results?.slice(0, 3).map((item, index) => {
                                        const badgeInfo = findFirstIntersection(item?.channels || [], channels)
                                        return (
                                            <div className="video_list--wrap" key={index}>
                                                <div className="video_list__detach">
                                                    <Tooltip
                                                        placement="right"
                                                        title={t('unpin_user_video')}
                                                    >
                                                        <div
                                                            className="video_list__detach--icon"
                                                            onClick={() => onDetachUser(item)}
                                                        >
                                                            {(isLoading.isShow && isLoading.id === item?.id) ?
                                                                <Loader size={20}/>
                                                                :
                                                                <CloseIcon/>
                                                            }
                                                        </div>
                                                    </Tooltip>
                                                </div>
                                                <VideoBlock
                                                    badge={badgeInfo}
                                                    channelsList={channelsList}
                                                    updateUserModal={(user) => {
                                                        getModalInfo(user)
                                                    }}
                                                    routs={[
                                                        {
                                                            path: '/dashboard/profile',
                                                            name: t('profile_profile')
                                                        },
                                                        {
                                                            id: user?.id ? user : user,
                                                            name: getAuthorName(user?.id ? user : user, null, i18n.language),
                                                        },
                                                        {
                                                            path: `/dashboard/profile`,
                                                            name: `${i18n.language === 'ru' ? 'Видео с' : 'Video with'} ` + getAuthorName(user?.id ? user : user, null, i18n.language),
                                                            active: true
                                                        }
                                                    ]}
                                                    video={item}
                                                />
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                        : <div className="empty_video">{t('empty_video')}</div>
                }
            </>
        }

        {!!highlights?.length && <div className={'participation-data__wrap'}>
            <span className={'participation-data__wrap--title'}>{t('profile_highlights')}</span>
            <span className="count-highlight">{highlights?.length}</span>
        </div>}

        {!!highlights?.length ?
            <div className="highlights-list">
                {highlights.sort((a, b) => b.id - a.id)?.map(({protocol_text, id, video}) => {
                    return (
                        <div className="protocol-item" key={id}>
                            <div className="protocol-item__content">
                                <div className="left">
                                    <div className="video-title">
                                        <span>{t('from')}</span>
                                        <Link
                                            to={`/dashboard/videos/video/${video?.id}/time/${protocol_text?.start_time}`}
                                            className="video-link">
                                            <PlayIcon/>
                                            <div className="video-link--text"> {video?.description}</div>
                                        </Link>
                                        {video?.created_at ? <span className="video-link--date">{formatDate(video?.created_at, i18n.language)}</span> : null}
                                    </div>
                                    <div className="text">
                                        {protocol_text?.text}
                                        <PinFillIcon className="highlights-star"
                                                     onClick={() => dispatch(DeleteHighlights(id))}/>
                                    </div>
                                    <div className="protocol-item__author_wrapper">
                                        <ProtocolAuthorBlock
                                            protocolItem={protocol_text}
                                            onClick={getModalInfo}
                                        />
                                        {(protocol_text?.start_time || protocol_text?.start_time === 0) &&
                                            <div className="time">
                                                {getTimeString(protocol_text?.start_time || 0)}
                                            </div>
                                        }
                                    </div>
                                </div>

                            </div>
                        </div>
                    )
                })}
            </div>
            : null}
        <ProfileRecordingBlock/>
        {
            user && <div className={'connect_person'}>
                <span>{t('connect_person')}</span>

                <CombinePersonsDropdown user={user} setVideoList={setVideoList}/>
            </div>
        }
        {personId ?
            <div className="remove-participation">
                <div className="remove-participation__wrap">
                    <span className="remove-participation__wrap--title">{t('remove_video_participation')}</span>
                    <div className="remove-participation__wrap--description">
					<span>
						{t('remove_video_participation_description')}
					</span>
                        <button onClick={deletePerson}>
                            <DeletingIcon/>
                            {t('button_delete_information')}
                        </button>
                    </div>
                </div>
            </div>
            : null}
        {
            (!avatar || avatar === 'None') && <div className={'add_img'}>
                <span>{t('add_img')}</span>

                <button className="add_img_button">
                    <PictureIcon/>
                    {t('upload_img')}
                </button>
            </div>
        }
        <Toast
            className='upload_video_answer'
            onClose={() => setToastInfo({isShow: false, type: toastInfo.type})}
            show={toastInfo.isShow}
            delay={3000}
            autohide
        >
            <div className="toasblock">
                {toastInfo.type === 'danger' ? <ErrorIcon/> : <SuccessIcon/>}
                <div className="toasblock__wrap">
						                <span
                                            className="toasblock__wrap--title">{toastInfo.type === 'danger' ? t('toast_error_title') : t('toast_success_title')}</span>
                    <span className="toasblock__wrap--text">{toastInfo?.text || ''}</span>
                </div>
            </div>
        </Toast>
    </div>
}

export default ParticipationData;

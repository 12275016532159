import React from 'react';
import './ProfileContacts.scss';
import {ArrowDownIcon, DeletingIcon, InfoCheckIcon, InfoIcon, PlusIcon, PlusNewIcon} from "../../../../image";
import {Accordion} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {replaceProfileContacts} from "./helpers";
import {Tooltip} from "antd";
import {hasPermission} from "../../../../utils/helperFunctions";

function ProfileContacts(props) {
	const {
		contactItems,
		setContactItems,
		haveChanges,
		setHaveChanges,
		errors,
		setErrors,
		personById
	} = props;
	const {t} = useTranslation();
	let hasContact = Object.values(contactItems).some(item => item.length > 0 && (item?.[0]?.length > 0)) ? '0' : ''

	function openContactItem(key) {
		if(contactItems[key].length < 2) {
			setContactItems({
				...contactItems,
				[key]: [...contactItems[key], '']
			})
		}
	}

	function deleteContactItem(key, index) {
		const sortArr = contactItems[key].filter((_, i) => i !== index);
		setContactItems({
			...contactItems,
			[key]: sortArr.length > 0 ? sortArr : ['']
		})

		const changes = new Map(haveChanges);
		if(contactItems[key]?.length === personById?.[key]?.length) {
			changes.set(key, index)
		} else {
			changes.delete(key)
		}
		if(setHaveChanges) setHaveChanges(changes)
	}

	function getContactValue(e, index) {
		const val = replaceProfileContacts(e);
		const {name, value} = e.target

		const phoneRegExp = /^[+\d]\d*$/;
		if(name.includes('phone') && val && !phoneRegExp.test(val)) {
			return;
		}
		setContactItems({
			...contactItems,
			[name]: contactItems[name].map((item, i) => {
				return index === i ? val : item
			})
		})

		setErrors({
			...errors, [name]: errors[name]?.map((mail, i) => {
				return i === index ? undefined : mail
			})
		})
		const changes = new Map(haveChanges);
		if(!personById?.[name]?.find(i => i === (value || undefined))) {
			changes.set(`${name}_${index}`, value)
		} else {
			changes.delete(`${name}_${index}`)
		}
		if(setHaveChanges) setHaveChanges(changes)
	}

	function focusContactValue(e, index) {
		const {name, value} = e.target;
		let val = value;

		if(name.includes('phone')) {
			val = val?.replaceAll('+', '')
			val = '+' + val
		}

		if(name.includes('linkedin') || name.includes('facebook')) {
			if(!val.startsWith("https://")) {
				return "https://" + val;
			}
		}

		setContactItems({
			...contactItems,
			[name]: contactItems[name].map((item, i) => {
				return index === i ? val : item
			})
		})
	}

	return (
		<Accordion defaultActiveKey={'0'} className='contacts-wrapper'>
			<Accordion.Item eventKey={''} className='profile-page__contacts'>
				<Accordion.Header className='contact-header'>
					<ArrowDownIcon className={`arrow-icon`}/>
					<span>{t('profile_contacts')}</span>
					{!hasContact ?
						<Tooltip
							placement="left"
							overlayClassName='contacts-wrapper__tooltip'
							title={
								<div className="contacts-wrapper__tooltip--wrap">
									<p className="contacts-wrapper__tooltip--wrap-title">{t('tooltip_contact_title')}</p>
									<p className="contacts-wrapper__tooltip--wrap-description">{t('tooltip_contact_description')}</p>
								</div>
							}
						>
							<InfoIcon className={`tooltip-icon`}/>
						</Tooltip>
						: <InfoCheckIcon/>
					}
				</Accordion.Header>
				<Accordion.Body>
					<div className="contact-item">
						{(contactItems?.email || []).map((item, index) => (
							<div className="input_group" key={index}>
								<label htmlFor="">{t('input_email')}</label>
								<input
									type="text"
									value={contactItems?.email[index]}
									name={'email'}
									disabled={!(hasPermission("users_update") || hasPermission("users_create"))}
									onChange={(e) => getContactValue(e, index)}
									placeholder={t('input_email')}
								/>
								<DeletingIcon
									onClick={() => deleteContactItem('email', index)}
									className="input_group__delete"
								/>
								{!!errors.email?.[index] && <div className="input_group__error">{errors.email?.[index]}</div>}
							</div>
						))}
						{contactItems?.email?.length < 2 &&
							<button
								className="add-contact"
								onClick={() => openContactItem('email')}
							>
								<PlusNewIcon/>
								<span>{t('profile_add_email')}</span>
							</button>
						}
					</div>
					<div className="contact-item">
						{(contactItems?.phone_number || []).map((item, index) => (
							<div className="input_group" key={index}>
								<label htmlFor="">{t('input_phone')}</label>
								<input
									type="text"
									value={contactItems?.phone_number[index]}
									disabled={!(hasPermission("users_update") || hasPermission("users_create"))}
									name={'phone_number'}
									onChange={(e) => getContactValue(e, index)}
									placeholder={'+0 (000) 000-00-00'}
								/>
								<DeletingIcon
									onClick={() => deleteContactItem('phone_number', index)}
									className="input_group__delete"
								/>
								{!!errors.phone_number?.[index] &&
									<div className="input_group__error">{errors.phone_number?.[index]}</div>}
							</div>
						))}
						{contactItems?.phone_number?.length < 2 &&
							<button
								className="add-contact"
								onClick={() => openContactItem('phone_number')}
							>
								<PlusNewIcon/>
								<span>{t('profile_add_phone')}</span>
							</button>
						}
					</div>
					<div className="contact-item">
						{(contactItems?.linkedin || []).map((item, index) => (
							<div className="input_group" key={index}>
								<label htmlFor="">{t('input_linkedin')}</label>
								<input
									type="text"
									value={contactItems?.linkedin[index]}
									name={'linkedin'}
									disabled={!(hasPermission("users_update") || hasPermission("users_create"))}
									onChange={(e) => getContactValue(e, index)}
									onFocus={(e) => focusContactValue(e, index)}
									placeholder={'https://www.'}
								/>
								<DeletingIcon
									onClick={() => deleteContactItem('linkedin', index)}
									className="input_group__delete"
								/>
								{!!errors.linkedin?.[index] && <div className="input_group__error">{errors.linkedin?.[index]}</div>}
							</div>
						))}
						{contactItems?.linkedin?.length < 2 &&
							<button
								className="add-contact"
								onClick={() => openContactItem('linkedin')}
							>
								<PlusNewIcon/>
								<span>{t('profile_add_link')}</span>
							</button>
						}
					</div>
					<div className="contact-item">
						{(contactItems?.facebook || []).map((item, index) => (
							<div className="input_group" key={index}>
								<label htmlFor="">{t('input_facebook')}</label>
								<input
									type="text"
									value={contactItems?.facebook[index]}
									name='facebook'
									disabled={!(hasPermission("users_update") || hasPermission("users_create"))}
									onChange={(e) => getContactValue(e, index)}
									placeholder='https://'
								/>
								<DeletingIcon onClick={() => deleteContactItem('facebook', index)}
								              className="input_group__delete"/>
								{!!errors.facebook?.[index] && <div className="input_group__error">{errors.facebook?.[index]}</div>}
							</div>
						))}
						{contactItems?.facebook?.length < 2 &&
							<button
								className="add-contact"
								onClick={() => openContactItem('facebook')}
							>
								<PlusNewIcon/>
								<span>{t('profile_add_link')}</span>
							</button>
						}
					</div>
				</Accordion.Body>
			</Accordion.Item>
		</Accordion>
	)
}

export default ProfileContacts;

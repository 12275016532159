// Import packages
import React, {Fragment, useEffect, useRef, useState} from 'react';
import {connect, useSelector} from "react-redux";

// Import assets
import './user-icons.scss';

// Import Components
import UserInfoModal from "../../../pages/Dashboard/containers/Users/modals/userInfoModal/UserInfoModal";

// Import Utils
import {getPropsFromState} from "../../../redux/mapStateToProps";
import {generateMediaUrl} from "../../../utils/generateMediaUrl";
import {getAuthorName} from "../../../utils/helperFunctions";
import {CloseIcon} from "../../../image";
import InfoTooltip from "../../../pages/Dashboard/containers/Profile/tooltip";
import {useTranslation} from "react-i18next";
import {Loader} from "../Loader";
import {GetVideos, UserDetachVideoService} from "../../../redux/actions";
import {Toast} from "react-bootstrap";
import {history} from "../../../utils/history";
import {ROUTERS_MAP} from "../../../utils/routes";

function UserIcons(props) {
    const {persons, placement, videoId, updateUserInfo, user, activePage} = props;
    const [showMenu, setShowMenu] = useState(false);
    const [showModal, setShowModal] = useState(false)
    const [currentUser, setCurrentUser] = useState(null)
    const [isLoading, setIsLoading] = useState({isShow: false, id: 0});
    const [toastInfo, setToastInfo] = useState({isShow: false, type: 'danger'});
    const userProfile = useSelector(state => state.profile.userProfile);

    const ref = useRef();
    const {t, i18n} = useTranslation();

    useEffect(() => {
        document.addEventListener('click', togglePersonsMenu, {capture: true});
        return () => {
            document.removeEventListener('click', togglePersonsMenu)
        }
    }, []);

    const togglePersonsMenu = (event) => {
        const path = event.composedPath ? event.composedPath() : event.path
        if (!path?.includes(ref?.current)) {
            setShowMenu(false)
        }
    }

    function toggleUserModal(person) {
        setShowModal(!showModal)
        if(userProfile?.attached_person?.id === person.person_id || userProfile?.id === person.user_id){
            history.push(ROUTERS_MAP.PROFILE);
        }else{
            setCurrentUser(person);
        }
    }

    const onDetachUser = async (person_id) => {
        try {
            setIsLoading({isShow: true, id: person_id});
            await props.UserDetachVideoService({
                video_id: Number(videoId),
                person_id: person_id || null,
            });
            setToastInfo({
                isShow: true,
                type: 'success',
                text: t('unpin_user_video_success')
            });
            if(updateUserInfo && !!user){
                const videosResponse = await props.GetVideos({
                    limit: 1000,
                    returnList: true,
                    filters: [
                        {
                            firstColumnVal: 'and',
                            secondColumnVal: 'staff',
                            thirdColumnVal: 'present',
                            fourthColumnVal: (user?.person_id || user?.id) ? (user?.person_id || user?.id) :  null
                        }
                    ]
                });
                updateUserInfo(videosResponse || {})
            }else {
                if(activePage){
                    await props.GetVideos({limit: 20, offset: (activePage - 1) * 20, manage: true});
                }else {
                    await props.GetVideos({
                        limit: 1000,
                        manage: false,
                        filters: []
                    });
                }
            }
            setIsLoading({isShow: false, id: 0});
            setShowMenu(!showMenu);
        } catch (e) {
            console.log(e);
            setToastInfo({
                isShow: true,
                type: 'danger',
                text: t('unpin_user_video_error')
            });
            setIsLoading({isShow: false, id: 0});
        }
    }

    return <div className="user-icons" ref={ref}
                onClick={(e) => {
                    e.stopPropagation()
                    setShowMenu(!showMenu)
                }}>
        {
            !!persons?.length && persons?.slice(0, 2)?.map(img => {
                return <img
                    src={generateMediaUrl(img?.avatar)}
                    key={img.id}
                    alt=""
                    className={`${img?.avatar ? 'known-user' : ''} user-icons__item`}
                />
            })
        }

        {persons?.length > 2 && <div
            className="user-icons__more">
            +{persons?.length - 2}
        </div>}

        {showMenu && <div className={`persons_menu 
        ${persons?.length > 4 ? 'large' : ''} 
        ${placement === 'left' ? 'left' : ''}`}
                          onClick={(e) => {
                              e.stopPropagation()
                              e.preventDefault()
                          }}>
            {
                persons?.map(item => {
                    const data = {
                        ...item,
                        person_id: item.id
                    }
                    delete data.id
                    return <div key={data?.person_id} className="person_item-wrap">
                        <div className="person_item" onClick={() => toggleUserModal(data)}>
                            <img
                                src={generateMediaUrl(data?.avatar)}
                                key={data?.person_id}
                                className={data?.avatar ? 'known-user' : ''}
                                alt=""
                            />
                            <div className="person_info">
                                <div className="name">{getAuthorName(data,null,i18n.language)}</div>
                                <div className="email">
                                    {data.email || 'user@gmail.com'}
                                </div>
                            </div>
                        </div>
                        <InfoTooltip
                          className={[((!(data?.image_path || data?.avatar) || (data?.image_path || data?.avatar) === "None") && !data?.first_name) ? "icon-disabled" : "", "person_info-delete\""].join(' ')}
                          description={t('unpin_user_video')}
                          placement={'left'}
                          customContent={
                              <p>{t('unpin_user_video')}</p>
                          }
                          elem={
                            <Fragment>
                                {(isLoading.isShow && isLoading.id === data?.person_id) ?
                                  <Loader size={20}/>
                                  :
                                <CloseIcon
                                  onClick={(e) => {
                                      e.stopPropagation();
                                      if(!isLoading.isShow && !!(data?.image_path || data?.avatar) && (data?.image_path || data?.avatar) !== "None")  {
                                          onDetachUser(item.id)
                                      }
                                  }}
                                  />
                                }
                            </Fragment>
                          }
                          />
                    </div>
                })
            }
        </div>}
        {showModal && <UserInfoModal show={showModal} closeModal={toggleUserModal} user={currentUser}
                                    />}
        <Toast
          className='toaster'
          bg={toastInfo.type} onClose={() => setToastInfo({isShow: false})}
          show={toastInfo.isShow}
          delay={3000}
          autohide
        >
            {toastInfo?.text || ''}
        </Toast>
    </div>;
}

const mapStateToProps = (state) => {
    return getPropsFromState(state, [])
};

const mapDispatchToProps = {
    GetVideos,
    UserDetachVideoService
};

export default connect(mapStateToProps, mapDispatchToProps)(UserIcons);

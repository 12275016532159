// Import packages
import React from 'react';

// Import assets
import './list-item.scss';
import {DeletingIcon, EditIcon, EllipsisIcon, VideoIcon} from "../../../../../../image";

// Import Utils
import {generateMediaUrl} from "../../../../../../utils/generateMediaUrl";
import {history} from "../../../../../../utils/history";
import {useTranslation} from "react-i18next";
import {getAuthorName} from "../../../../../../utils/helperFunctions";
import {Popover} from "antd";

function ListItem({data, openModal, setCurrentUser, onDeleteUser}) {
    const {t, i18n} = useTranslation();

    function toggleUpdate() {
        setCurrentUser(data)
        openModal()
    }

    function navigateTo() {
        !!data?.videos_count && history.push({
            pathname: `/dashboard/videos/userVideos/${data.person_id ? data.person_id : data.user_id}`,
            state: {
                personId: data.person_id,
                routs: [
                    {
                        path: '/dashboard/users/new',
                        name: t('members_title')
                    },
                    {
                        id: data.person_id ? data.person_id : data.user_id,
                        name: getAuthorName(data, null, i18n.language),
                    },
                    {
                        path: `/dashboard/videos/userVideos/${data.person_id ? data.person_id : data.user_id}`,
                        name: 'Видео с ' + getAuthorName(data, null, i18n.language),
                        active: true
                    }
                ]
            }
        })
    }

    return (
        <div className="list-item new">
            <div className="list-item__content">
                <div className="user_info item_col">
                    <img
                        src={generateMediaUrl(data?.avatar)}
                        alt=""
                        className="user_info__img"
                    />
                </div>
                <div className="item_col">
                    <div className="user_info__content">
                        <div className="name" onClick={toggleUpdate}>
                            #{data?.person_id}
                        </div>
                    </div>
                </div>
                <div className="item_col">
                    {data?.date ? data?.date : '-'}
                </div>
                <div className="video_count item_col" onClick={navigateTo}>
                    <VideoIcon/> {data?.videos_count}
                </div>
            </div>
            <div className="list-item__actions">
                <Popover
                    arrow={false}
                    trigger='click'
                    placement="rightTop"
                    overlayClassName='popover_edit-menu'
                    content={
                        <>
                            <div
                                className={'popover_edit-menu--item'}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    toggleUpdate()
                                }}
                            >
                                <EditIcon onClick={toggleUpdate}/> {t('button_edit')}
                            </div>
                            <div
                                className={'popover_edit-menu--item delete'}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    onDeleteUser(data)
                                }}
                            >
                                <DeletingIcon className={''}/> {t('button_delete')}
                            </div>
                        </>
                    }
                >
                    <div className="popover_edit-menu--settings">
                        <EllipsisIcon/>
                    </div>
                </Popover>
            </div>
        </div>
    );
}

export default ListItem;

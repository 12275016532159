// Import packages
import React from 'react';

// Import assets
import './delete-person.scss';
import {useTranslation} from "react-i18next";
import {DeletingIcon} from "../../../image";

// Import Components

// Import Utils

function DeletePerson(props) {
    const {deleteAccount} = props;

    const {t} = useTranslation();

    return <div className="delete-person">
    <div className="delete-person__title">{t('profile_delete_title')}</div>
        <div className="delete-person__content">
            <div className="delete-person__text">{t('profile_delete_text')}</div>
            <button onClick={deleteAccount} className="delete-person__button">
                <DeletingIcon/>
                {t('profile_delete_button')}
            </button>
        </div>
    </div>;
}

export default DeletePerson;
